import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { ReportApi } from "@/components/modules/activity/services/api/ReportApi";

const report = new ReportApi()

class State {
  agReport: any
  isAgReportBusy: true
  agReportFaster: any
  isAgReportFasterBusy: true
  agReportFasterMax: any
  isAgReportFasterMaxBusy: true
  giReportFasterMax: any
  isGiReportFasterMaxBusy: true
  gzReportFasterMax: any
  isGzReportFasterMaxBusy: true
  edReportFasterMax: any
  isEdReportFasterMaxBusy: true
  agChildReportFasterMax: any
  isChildReportFasterMaxBusy: true
  isNgReportFasterMaxBusy: true
  ngReport: Record<string, any>
  msReport: any
  isMsReportBusy: true
}

const getters = <GetterTree<State, any>>{
  getAgReport(state) {
    return state.agReport
  },

  getIsAgReportBusy(state) {
    return state.isAgReportBusy
  },

  getAgReportFaster(state) {
    return state.agReportFaster
  },

  getIsAgReportFasterBusy(state) {
    return state.isAgReportFasterBusy
  },

  getAgReportFasterMax(state) {
    return state.agReportFasterMax
  },

  getIsAgReportFasterMaxBusy(state) {
    return state.isAgReportFasterMaxBusy
  },

  getGiReportFasterMax(state) {
    return state.giReportFasterMax
  },

  getIsGiReportFasterMaxBusy(state) {
    return state.isGiReportFasterMaxBusy
  },

  getGzReportFasterMax(state) {
    return state.gzReportFasterMax
  },

  getIsGzReportFasterMaxBusy(state) {
    return state.isGzReportFasterMaxBusy
  },

  getEdReportFasterMax(state) {
    return state.edReportFasterMax
  },

  getIsEdReportFasterMaxBusy(state) {
    return state.isEdReportFasterMaxBusy
  },

  getAgChildReportFasterMax(state) {
    return state.agChildReportFasterMax
  },

  getIsChildReportFasterMaxBusy(state) {
    return state.isChildReportFasterMaxBusy
  },

  getIsNgReportBusy(state) {
    return state.isNgReportFasterMaxBusy
  },

  getNgReport(state) {
    return state.ngReport
  },

  getMsReport(state) {
    return state.msReport
  },

  getIsMsReportBusy(state) {
    return state.isMsReportBusy
  },
}

const mutations = <MutationTree<State>>{
  setAgReport(state, payload) {
    state.agReport = payload
  },

  setIsAgReportBusy(state, payload) {
    state.isAgReportBusy = payload
  },

  setAgReportFaster(state, payload) {
    state.agReportFaster = payload
  },

  setIsAgReportFasterBusy(state, payload) {
    state.isAgReportFasterBusy = payload
  },

  setAgReportFasterMax(state, payload) {
    state.agReportFasterMax = payload
  },

  setIsAgReportFasterMaxBusy(state, payload) {
    state.isAgReportFasterMaxBusy = payload
  },

  setGiReportFasterMax(state, payload) {
    state.giReportFasterMax = payload
  },

  setIsGiReportFasterMaxBusy(state, payload) {
    state.isGiReportFasterMaxBusy = payload
  },

  setGzReportFasterMax(state, payload) {
    state.gzReportFasterMax = payload
  },

  setIsGzReportFasterMaxBusy(state, payload) {
    state.isGzReportFasterMaxBusy = payload
  },

  setEdReportFasterMax(state, payload) {
    state.edReportFasterMax = payload
  },

  setIsEdReportFasterMaxBusy(state, payload) {
    state.isEdReportFasterMaxBusy = payload
  },

  setAgChildReportFasterMax(state, payload) {
    state.agChildReportFasterMax = payload
  },

  setIsChildReportFasterMaxBusy(state, payload) {
    state.isChildReportFasterMaxBusy = payload
  },

  setIsNgReportFasterMaxBusy(state, payload) {
    state.isNgReportFasterMaxBusy = payload
  },

  setNgReportFasterMax(state, payload) {
    state.ngReport = payload
  },

  setMsReport(state, payload) {
    state.msReport = payload
  },

  setIsMsReportBusy(state, payload) {
    state.isMsReportBusy = payload
  },
}

const actions = <ActionTree<State, any>>{
  getAgReport({ commit }, data) {
    commit('setIsAgReportBusy', true)

    return new Promise((resolve, reject) => {
      report.getAgReportList(data).then(
        (message: any) => {
          const items = Object.fromEntries(message.getValuesMap().toObject())
          console.log('message ag report', Object.fromEntries(message.getValuesMap().toObject()))

          commit('setAgReport', items)
          commit('setIsAgReportBusy', false)

          resolve(items)
        },
        (error) => {
          console.log('message ag err', error)
          commit('setAgReport', [])
          commit('setIsAgReportBusy', false)

          reject(error)
        }
      )
    })
  },

  getAgReportFaster({ commit }, data) {
    commit('setIsAgReportFasterBusy', true)

    return new Promise((resolve, reject) => {
      report.getAgFasterReportList(data).then(
        (message: any) => {
          const items = Object.fromEntries(message.getValuesMap().toObject())
          console.log('message ag report Faster', Object.fromEntries(message.getValuesMap().toObject()))

          commit('setAgReportFaster', items)
          commit('setIsAgReportFasterBusy', false)

          resolve(items)
        },
        (error) => {
          console.log('message ag err', error)
          commit('setAgReportFaster', [])
          commit('setIsAgReportFasterBusy', false)

          reject(error)
        }
      )
    })
  },

  getAgReportFasterMax({ commit }, data) {
    commit('setIsAgReportFasterMaxBusy', true)

    return new Promise((resolve, reject) => {
      report.getAgFasterMaxReportList(data).then(
        (message: any) => {
          const items = message
          console.log('message ag report Faster', message)

          commit('setAgReportFasterMax', items)
          commit('setIsAgReportFasterMaxBusy', false)

          resolve(items)
        },
        (error) => {
          console.log('message ag err', error)
          commit('setAgReportFasterMax', [])
          commit('setIsAgReportFasterMaxBusy', false)

          reject(error)
        }
      )
    })
  },

  getGiReportFasterMax({ commit }, data) {
    commit('setIsGiReportFasterMaxBusy', true)

    return new Promise((resolve, reject) => {
      report.getGiFasterMaxReportList(data).then(
        (message: any) => {
          const items = message
          console.log('message GI report Faster', message)

          commit('setGiReportFasterMax', items)
          commit('setIsGiReportFasterMaxBusy', false)

          resolve(items)
        },
        (error) => {
          console.log('message Gi err', error)
          commit('setGiReportFasterMax', [])
          commit('setIsGiReportFasterMaxBusy', false)

          reject(error)
        }
      )
    })
  },

  getGzReportFasterMax({ commit }, data) {
    commit('setIsGzReportFasterMaxBusy', true)

    return new Promise((resolve, reject) => {
      report.getGzFasterMaxReportList(data).then(
        (message: any) => {
          const items = message
          console.log('message GZ report Faster', message)

          commit('setGzReportFasterMax', items)
          commit('setIsGzReportFasterMaxBusy', false)

          resolve(items)
        },
        (error) => {
          console.log('message Gi err', error)
          commit('setGzReportFasterMax', [])
          commit('setIsGzReportFasterMaxBusy', false)

          reject(error)
        }
      )
    })
  },

  getEdReportFasterMax({ commit }, data) {
    commit('setIsEdReportFasterMaxBusy', true)

    return new Promise((resolve, reject) => {
      report.getEdFasterMaxReportList(data).then(
        (message: any) => {
          const items = message
          console.log('message ED report Faster', message)

          commit('setEdReportFasterMax', items)
          commit('setIsEdReportFasterMaxBusy', false)

          resolve(items)
        },
        (error) => {
          console.log('message Ed err', error)
          commit('setEdReportFasterMax', [])
          commit('setIsEdReportFasterMaxBusy', false)

          reject(error)
        }
      )
    })
  },

  getAgChildReportFasterMax({ commit }, data) {
    commit('setIsChildReportFasterMaxBusy', true)

    return new Promise((resolve, reject) => {
      report.getAGChildMaxReportList(data).then(
        (message) => {
          console.log('message Ag child report Faster', message)

          commit('setAgChildReportFasterMax', message)
          commit('setIsChildReportFasterMaxBusy', false)

          resolve(message)
        },
        (error) => {
          console.log('message Ag child err', error)
          commit('setAgChildReportFasterMax', [])
          commit('setIsChildReportFasterMaxBusy', false)

          reject(error)
        }
      )
    })
  },

  getNgFasterMaxReportList({ commit }, data) {
    commit('setIsChildReportFasterMaxBusy', true)

    return report.getNgFasterMaxReportList(data).then(
      (message) => {
        console.log('message Ng report', message)

        commit('setNgReportFasterMax', message)
        commit('setIsNgReportFasterMaxBusy', false)

        return message
      },
      (error) => {
        console.log('message Ng err', error)
        commit('setNgReportFasterMax', [])
        commit('setIsNgReportFasterMaxBusy', false)

        return error
      }
    )
  },

  getMsReportFasterMax({ commit }, data) {
    commit('setIsMsReportBusy', true)

    return new Promise((resolve, reject) => {
      report.getMsFasterMaxReportList(data).then(
        (message: any) => {
          const items = message
          console.log('message MS report', message)

          commit('setMsReport', items)
          commit('setIsMsReportBusy', false)

          resolve(items)
        },
        (error) => {
          console.log('message MS err', error)
          commit('setMsReport', [])
          commit('setIsMsReportBusy', false)

          reject(error)
        }
      )
    })
  },
}

const agReportModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default agReportModule
