/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: project_intersection.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as project_intersection_pb from './project_intersection_pb'; // proto import: "project_intersection.proto"


export class DashboardProjectIntersectionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListDashboardProjectIntersection = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectIntersectionService/ListDashboardProjectIntersection',
    grpcWeb.MethodType.UNARY,
    project_intersection_pb.ListDashboardProjectIntersectionRequest,
    project_intersection_pb.ListDashboardProjectIntersectionResponse,
    (request: project_intersection_pb.ListDashboardProjectIntersectionRequest) => {
      return request.serializeBinary();
    },
    project_intersection_pb.ListDashboardProjectIntersectionResponse.deserializeBinary
  );

  listDashboardProjectIntersection(
    request: project_intersection_pb.ListDashboardProjectIntersectionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_intersection_pb.ListDashboardProjectIntersectionResponse>;

  listDashboardProjectIntersection(
    request: project_intersection_pb.ListDashboardProjectIntersectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_intersection_pb.ListDashboardProjectIntersectionResponse) => void): grpcWeb.ClientReadableStream<project_intersection_pb.ListDashboardProjectIntersectionResponse>;

  listDashboardProjectIntersection(
    request: project_intersection_pb.ListDashboardProjectIntersectionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_intersection_pb.ListDashboardProjectIntersectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectIntersectionService/ListDashboardProjectIntersection',
        request,
        metadata || {},
        this.methodDescriptorListDashboardProjectIntersection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectIntersectionService/ListDashboardProjectIntersection',
    request,
    metadata || {},
    this.methodDescriptorListDashboardProjectIntersection);
  }

  methodDescriptorListDashboardProjectIntersectionActivity = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectIntersectionService/ListDashboardProjectIntersectionActivity',
    grpcWeb.MethodType.UNARY,
    project_intersection_pb.ListDashboardProjectIntersectionActivityRequest,
    project_intersection_pb.ListDashboardProjectIntersectionActivityResponse,
    (request: project_intersection_pb.ListDashboardProjectIntersectionActivityRequest) => {
      return request.serializeBinary();
    },
    project_intersection_pb.ListDashboardProjectIntersectionActivityResponse.deserializeBinary
  );

  listDashboardProjectIntersectionActivity(
    request: project_intersection_pb.ListDashboardProjectIntersectionActivityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_intersection_pb.ListDashboardProjectIntersectionActivityResponse>;

  listDashboardProjectIntersectionActivity(
    request: project_intersection_pb.ListDashboardProjectIntersectionActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_intersection_pb.ListDashboardProjectIntersectionActivityResponse) => void): grpcWeb.ClientReadableStream<project_intersection_pb.ListDashboardProjectIntersectionActivityResponse>;

  listDashboardProjectIntersectionActivity(
    request: project_intersection_pb.ListDashboardProjectIntersectionActivityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_intersection_pb.ListDashboardProjectIntersectionActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectIntersectionService/ListDashboardProjectIntersectionActivity',
        request,
        metadata || {},
        this.methodDescriptorListDashboardProjectIntersectionActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectIntersectionService/ListDashboardProjectIntersectionActivity',
    request,
    metadata || {},
    this.methodDescriptorListDashboardProjectIntersectionActivity);
  }

  methodDescriptorListDashboardProjectIntersectionRegistration = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectIntersectionService/ListDashboardProjectIntersectionRegistration',
    grpcWeb.MethodType.UNARY,
    project_intersection_pb.ListDashboardProjectIntersectionRegistrationRequest,
    project_intersection_pb.ListDashboardProjectIntersectionRegistrationResponse,
    (request: project_intersection_pb.ListDashboardProjectIntersectionRegistrationRequest) => {
      return request.serializeBinary();
    },
    project_intersection_pb.ListDashboardProjectIntersectionRegistrationResponse.deserializeBinary
  );

  listDashboardProjectIntersectionRegistration(
    request: project_intersection_pb.ListDashboardProjectIntersectionRegistrationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_intersection_pb.ListDashboardProjectIntersectionRegistrationResponse>;

  listDashboardProjectIntersectionRegistration(
    request: project_intersection_pb.ListDashboardProjectIntersectionRegistrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_intersection_pb.ListDashboardProjectIntersectionRegistrationResponse) => void): grpcWeb.ClientReadableStream<project_intersection_pb.ListDashboardProjectIntersectionRegistrationResponse>;

  listDashboardProjectIntersectionRegistration(
    request: project_intersection_pb.ListDashboardProjectIntersectionRegistrationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_intersection_pb.ListDashboardProjectIntersectionRegistrationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectIntersectionService/ListDashboardProjectIntersectionRegistration',
        request,
        metadata || {},
        this.methodDescriptorListDashboardProjectIntersectionRegistration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectIntersectionService/ListDashboardProjectIntersectionRegistration',
    request,
    metadata || {},
    this.methodDescriptorListDashboardProjectIntersectionRegistration);
  }

  methodDescriptorAccountListDashboardProjectIntersection = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectIntersectionService/AccountListDashboardProjectIntersection',
    grpcWeb.MethodType.UNARY,
    project_intersection_pb.AccountListDashboardProjectIntersectionRequest,
    project_intersection_pb.AccountListDashboardProjectIntersectionResponse,
    (request: project_intersection_pb.AccountListDashboardProjectIntersectionRequest) => {
      return request.serializeBinary();
    },
    project_intersection_pb.AccountListDashboardProjectIntersectionResponse.deserializeBinary
  );

  accountListDashboardProjectIntersection(
    request: project_intersection_pb.AccountListDashboardProjectIntersectionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_intersection_pb.AccountListDashboardProjectIntersectionResponse>;

  accountListDashboardProjectIntersection(
    request: project_intersection_pb.AccountListDashboardProjectIntersectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_intersection_pb.AccountListDashboardProjectIntersectionResponse) => void): grpcWeb.ClientReadableStream<project_intersection_pb.AccountListDashboardProjectIntersectionResponse>;

  accountListDashboardProjectIntersection(
    request: project_intersection_pb.AccountListDashboardProjectIntersectionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_intersection_pb.AccountListDashboardProjectIntersectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectIntersectionService/AccountListDashboardProjectIntersection',
        request,
        metadata || {},
        this.methodDescriptorAccountListDashboardProjectIntersection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectIntersectionService/AccountListDashboardProjectIntersection',
    request,
    metadata || {},
    this.methodDescriptorAccountListDashboardProjectIntersection);
  }

  methodDescriptorAccountListDashboardProjectIntersectionActivity = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectIntersectionService/AccountListDashboardProjectIntersectionActivity',
    grpcWeb.MethodType.UNARY,
    project_intersection_pb.AccountListDashboardProjectIntersectionActivityRequest,
    project_intersection_pb.AccountListDashboardProjectIntersectionActivityResponse,
    (request: project_intersection_pb.AccountListDashboardProjectIntersectionActivityRequest) => {
      return request.serializeBinary();
    },
    project_intersection_pb.AccountListDashboardProjectIntersectionActivityResponse.deserializeBinary
  );

  accountListDashboardProjectIntersectionActivity(
    request: project_intersection_pb.AccountListDashboardProjectIntersectionActivityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_intersection_pb.AccountListDashboardProjectIntersectionActivityResponse>;

  accountListDashboardProjectIntersectionActivity(
    request: project_intersection_pb.AccountListDashboardProjectIntersectionActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_intersection_pb.AccountListDashboardProjectIntersectionActivityResponse) => void): grpcWeb.ClientReadableStream<project_intersection_pb.AccountListDashboardProjectIntersectionActivityResponse>;

  accountListDashboardProjectIntersectionActivity(
    request: project_intersection_pb.AccountListDashboardProjectIntersectionActivityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_intersection_pb.AccountListDashboardProjectIntersectionActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectIntersectionService/AccountListDashboardProjectIntersectionActivity',
        request,
        metadata || {},
        this.methodDescriptorAccountListDashboardProjectIntersectionActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectIntersectionService/AccountListDashboardProjectIntersectionActivity',
    request,
    metadata || {},
    this.methodDescriptorAccountListDashboardProjectIntersectionActivity);
  }

  methodDescriptorPersonalAccountListDashboardProjectIntersection = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectIntersectionService/PersonalAccountListDashboardProjectIntersection',
    grpcWeb.MethodType.UNARY,
    project_intersection_pb.PersonalAccountListDashboardProjectIntersectionRequest,
    project_intersection_pb.PersonalAccountListDashboardProjectIntersectionResponse,
    (request: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionRequest) => {
      return request.serializeBinary();
    },
    project_intersection_pb.PersonalAccountListDashboardProjectIntersectionResponse.deserializeBinary
  );

  personalAccountListDashboardProjectIntersection(
    request: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_intersection_pb.PersonalAccountListDashboardProjectIntersectionResponse>;

  personalAccountListDashboardProjectIntersection(
    request: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionResponse) => void): grpcWeb.ClientReadableStream<project_intersection_pb.PersonalAccountListDashboardProjectIntersectionResponse>;

  personalAccountListDashboardProjectIntersection(
    request: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectIntersectionService/PersonalAccountListDashboardProjectIntersection',
        request,
        metadata || {},
        this.methodDescriptorPersonalAccountListDashboardProjectIntersection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectIntersectionService/PersonalAccountListDashboardProjectIntersection',
    request,
    metadata || {},
    this.methodDescriptorPersonalAccountListDashboardProjectIntersection);
  }

  methodDescriptorPersonalAccountListDashboardProjectIntersectionActivity = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectIntersectionService/PersonalAccountListDashboardProjectIntersectionActivity',
    grpcWeb.MethodType.UNARY,
    project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityRequest,
    project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityResponse,
    (request: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityRequest) => {
      return request.serializeBinary();
    },
    project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityResponse.deserializeBinary
  );

  personalAccountListDashboardProjectIntersectionActivity(
    request: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityResponse>;

  personalAccountListDashboardProjectIntersectionActivity(
    request: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityResponse) => void): grpcWeb.ClientReadableStream<project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityResponse>;

  personalAccountListDashboardProjectIntersectionActivity(
    request: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_intersection_pb.PersonalAccountListDashboardProjectIntersectionActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectIntersectionService/PersonalAccountListDashboardProjectIntersectionActivity',
        request,
        metadata || {},
        this.methodDescriptorPersonalAccountListDashboardProjectIntersectionActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectIntersectionService/PersonalAccountListDashboardProjectIntersectionActivity',
    request,
    metadata || {},
    this.methodDescriptorPersonalAccountListDashboardProjectIntersectionActivity);
  }

}

