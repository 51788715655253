import script from "./ActionTable.vue?vue&type=script&setup=true&lang=ts"
export * from "./ActionTable.vue?vue&type=script&setup=true&lang=ts"

import "./ActionTable.vue?vue&type=style&index=0&id=693297d1&lang=scss"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTr from 'quasar/src/components/table/QTr.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QInnerLoading,QSpinner,QTd,QDialog,QBtn,QTooltip,QTr});
