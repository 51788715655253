import script from "./Breadcrumbs.vue?vue&type=script&setup=true&lang=ts"
export * from "./Breadcrumbs.vue?vue&type=script&setup=true&lang=ts"

import "./Breadcrumbs.vue?vue&type=style&index=0&id=282d08de&style=scss&lang=css"

const __exports__ = script;

export default __exports__
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBreadcrumbs,QIcon,QBreadcrumbsEl});
