import script from "./UserProjects.vue?vue&type=script&setup=true&lang=ts"
export * from "./UserProjects.vue?vue&type=script&setup=true&lang=ts"

import "./UserProjects.vue?vue&type=style&index=0&id=0e677be9&lang=scss"

const __exports__ = script;

export default __exports__
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCheckbox,QBtn,QDialog,QCard,QCardSection,QInput,QFile,QSpace,QBadge,QTable,QTr,QTh,QTd,QSpinner,QIcon,QTooltip,QList,QItem,QItemSection,QInnerLoading});
