/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: account_answers.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as account_answers_pb from './account_answers_pb';


export class AccountAnswersServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListAccountAnswers = new grpcWeb.MethodDescriptor(
    '/proto.AccountAnswersService/ListAccountAnswers',
    grpcWeb.MethodType.UNARY,
    account_answers_pb.ListAccountAnswersRequest,
    account_answers_pb.ListAccountAnswersResponse,
    (request: account_answers_pb.ListAccountAnswersRequest) => {
      return request.serializeBinary();
    },
    account_answers_pb.ListAccountAnswersResponse.deserializeBinary
  );

  listAccountAnswers(
    request: account_answers_pb.ListAccountAnswersRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_answers_pb.ListAccountAnswersResponse>;

  listAccountAnswers(
    request: account_answers_pb.ListAccountAnswersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_answers_pb.ListAccountAnswersResponse) => void): grpcWeb.ClientReadableStream<account_answers_pb.ListAccountAnswersResponse>;

  listAccountAnswers(
    request: account_answers_pb.ListAccountAnswersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_answers_pb.ListAccountAnswersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AccountAnswersService/ListAccountAnswers',
        request,
        metadata || {},
        this.methodDescriptorListAccountAnswers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AccountAnswersService/ListAccountAnswers',
    request,
    metadata || {},
    this.methodDescriptorListAccountAnswers);
  }

  methodDescriptorListAccountClassGroups = new grpcWeb.MethodDescriptor(
    '/proto.AccountAnswersService/ListAccountClassGroups',
    grpcWeb.MethodType.UNARY,
    account_answers_pb.ListAccountClassGroupsRequest,
    account_answers_pb.ListAccountClassGroupsResponse,
    (request: account_answers_pb.ListAccountClassGroupsRequest) => {
      return request.serializeBinary();
    },
    account_answers_pb.ListAccountClassGroupsResponse.deserializeBinary
  );

  listAccountClassGroups(
    request: account_answers_pb.ListAccountClassGroupsRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_answers_pb.ListAccountClassGroupsResponse>;

  listAccountClassGroups(
    request: account_answers_pb.ListAccountClassGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_answers_pb.ListAccountClassGroupsResponse) => void): grpcWeb.ClientReadableStream<account_answers_pb.ListAccountClassGroupsResponse>;

  listAccountClassGroups(
    request: account_answers_pb.ListAccountClassGroupsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_answers_pb.ListAccountClassGroupsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AccountAnswersService/ListAccountClassGroups',
        request,
        metadata || {},
        this.methodDescriptorListAccountClassGroups,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AccountAnswersService/ListAccountClassGroups',
    request,
    metadata || {},
    this.methodDescriptorListAccountClassGroups);
  }

}

