/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.12.4
// source: report.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as report_pb from './report_pb';


export class ReportServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetAGReport = new grpcWeb.MethodDescriptor(
    '/proto.ReportService/GetAGReport',
    grpcWeb.MethodType.UNARY,
    report_pb.AGReportRequest,
    report_pb.AGReportResponse,
    (request: report_pb.AGReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.AGReportResponse.deserializeBinary
  );

  getAGReport(
    request: report_pb.AGReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.AGReportResponse>;

  getAGReport(
    request: report_pb.AGReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.AGReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.AGReportResponse>;

  getAGReport(
    request: report_pb.AGReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.AGReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ReportService/GetAGReport',
        request,
        metadata || {},
        this.methodDescriptorGetAGReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ReportService/GetAGReport',
    request,
    metadata || {},
    this.methodDescriptorGetAGReport);
  }

  methodDescriptorGetAGReportGoroutine = new grpcWeb.MethodDescriptor(
    '/proto.ReportService/GetAGReportGoroutine',
    grpcWeb.MethodType.UNARY,
    report_pb.AGReportGoroutineRequest,
    report_pb.AGReportGoroutineResponse,
    (request: report_pb.AGReportGoroutineRequest) => {
      return request.serializeBinary();
    },
    report_pb.AGReportGoroutineResponse.deserializeBinary
  );

  getAGReportGoroutine(
    request: report_pb.AGReportGoroutineRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.AGReportGoroutineResponse>;

  getAGReportGoroutine(
    request: report_pb.AGReportGoroutineRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.AGReportGoroutineResponse) => void): grpcWeb.ClientReadableStream<report_pb.AGReportGoroutineResponse>;

  getAGReportGoroutine(
    request: report_pb.AGReportGoroutineRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.AGReportGoroutineResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ReportService/GetAGReportGoroutine',
        request,
        metadata || {},
        this.methodDescriptorGetAGReportGoroutine,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ReportService/GetAGReportGoroutine',
    request,
    metadata || {},
    this.methodDescriptorGetAGReportGoroutine);
  }

  methodDescriptorGetAGReportGoroutineMax = new grpcWeb.MethodDescriptor(
    '/proto.ReportService/GetAGReportGoroutineMax',
    grpcWeb.MethodType.UNARY,
    report_pb.AGReportGoroutineMaxRequest,
    report_pb.AGReportGoroutineMaxResponse,
    (request: report_pb.AGReportGoroutineMaxRequest) => {
      return request.serializeBinary();
    },
    report_pb.AGReportGoroutineMaxResponse.deserializeBinary
  );

  getAGReportGoroutineMax(
    request: report_pb.AGReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.AGReportGoroutineMaxResponse>;

  getAGReportGoroutineMax(
    request: report_pb.AGReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.AGReportGoroutineMaxResponse) => void): grpcWeb.ClientReadableStream<report_pb.AGReportGoroutineMaxResponse>;

  getAGReportGoroutineMax(
    request: report_pb.AGReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.AGReportGoroutineMaxResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ReportService/GetAGReportGoroutineMax',
        request,
        metadata || {},
        this.methodDescriptorGetAGReportGoroutineMax,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ReportService/GetAGReportGoroutineMax',
    request,
    metadata || {},
    this.methodDescriptorGetAGReportGoroutineMax);
  }

  methodDescriptorGetGIReportGoroutineMax = new grpcWeb.MethodDescriptor(
    '/proto.ReportService/GetGIReportGoroutineMax',
    grpcWeb.MethodType.UNARY,
    report_pb.GIReportGoroutineMaxRequest,
    report_pb.GIReportGoroutineMaxResponse,
    (request: report_pb.GIReportGoroutineMaxRequest) => {
      return request.serializeBinary();
    },
    report_pb.GIReportGoroutineMaxResponse.deserializeBinary
  );

  getGIReportGoroutineMax(
    request: report_pb.GIReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GIReportGoroutineMaxResponse>;

  getGIReportGoroutineMax(
    request: report_pb.GIReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GIReportGoroutineMaxResponse) => void): grpcWeb.ClientReadableStream<report_pb.GIReportGoroutineMaxResponse>;

  getGIReportGoroutineMax(
    request: report_pb.GIReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GIReportGoroutineMaxResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ReportService/GetGIReportGoroutineMax',
        request,
        metadata || {},
        this.methodDescriptorGetGIReportGoroutineMax,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ReportService/GetGIReportGoroutineMax',
    request,
    metadata || {},
    this.methodDescriptorGetGIReportGoroutineMax);
  }

  methodDescriptorGetGZReportGoroutineMax = new grpcWeb.MethodDescriptor(
    '/proto.ReportService/GetGZReportGoroutineMax',
    grpcWeb.MethodType.UNARY,
    report_pb.GZReportGoroutineMaxRequest,
    report_pb.GZReportGoroutineMaxResponse,
    (request: report_pb.GZReportGoroutineMaxRequest) => {
      return request.serializeBinary();
    },
    report_pb.GZReportGoroutineMaxResponse.deserializeBinary
  );

  getGZReportGoroutineMax(
    request: report_pb.GZReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GZReportGoroutineMaxResponse>;

  getGZReportGoroutineMax(
    request: report_pb.GZReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GZReportGoroutineMaxResponse) => void): grpcWeb.ClientReadableStream<report_pb.GZReportGoroutineMaxResponse>;

  getGZReportGoroutineMax(
    request: report_pb.GZReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GZReportGoroutineMaxResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ReportService/GetGZReportGoroutineMax',
        request,
        metadata || {},
        this.methodDescriptorGetGZReportGoroutineMax,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ReportService/GetGZReportGoroutineMax',
    request,
    metadata || {},
    this.methodDescriptorGetGZReportGoroutineMax);
  }

  methodDescriptorGetEDReportGoroutineMax = new grpcWeb.MethodDescriptor(
    '/proto.ReportService/GetEDReportGoroutineMax',
    grpcWeb.MethodType.UNARY,
    report_pb.EDReportGoroutineMaxRequest,
    report_pb.EDReportGoroutineMaxResponse,
    (request: report_pb.EDReportGoroutineMaxRequest) => {
      return request.serializeBinary();
    },
    report_pb.EDReportGoroutineMaxResponse.deserializeBinary
  );

  getEDReportGoroutineMax(
    request: report_pb.EDReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.EDReportGoroutineMaxResponse>;

  getEDReportGoroutineMax(
    request: report_pb.EDReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.EDReportGoroutineMaxResponse) => void): grpcWeb.ClientReadableStream<report_pb.EDReportGoroutineMaxResponse>;

  getEDReportGoroutineMax(
    request: report_pb.EDReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.EDReportGoroutineMaxResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ReportService/GetEDReportGoroutineMax',
        request,
        metadata || {},
        this.methodDescriptorGetEDReportGoroutineMax,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ReportService/GetEDReportGoroutineMax',
    request,
    metadata || {},
    this.methodDescriptorGetEDReportGoroutineMax);
  }

  methodDescriptorGetAGChildReportGoroutineMax = new grpcWeb.MethodDescriptor(
    '/proto.ReportService/GetAGChildReportGoroutineMax',
    grpcWeb.MethodType.UNARY,
    report_pb.AGChildReportGoroutineMaxRequest,
    report_pb.AGChildReportGoroutineMaxResponse,
    (request: report_pb.AGChildReportGoroutineMaxRequest) => {
      return request.serializeBinary();
    },
    report_pb.AGChildReportGoroutineMaxResponse.deserializeBinary
  );

  getAGChildReportGoroutineMax(
    request: report_pb.AGChildReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.AGChildReportGoroutineMaxResponse>;

  getAGChildReportGoroutineMax(
    request: report_pb.AGChildReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.AGChildReportGoroutineMaxResponse) => void): grpcWeb.ClientReadableStream<report_pb.AGChildReportGoroutineMaxResponse>;

  getAGChildReportGoroutineMax(
    request: report_pb.AGChildReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.AGChildReportGoroutineMaxResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ReportService/GetAGChildReportGoroutineMax',
        request,
        metadata || {},
        this.methodDescriptorGetAGChildReportGoroutineMax,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ReportService/GetAGChildReportGoroutineMax',
    request,
    metadata || {},
    this.methodDescriptorGetAGChildReportGoroutineMax);
  }

  methodDescriptorGetNGReportGoroutineMax = new grpcWeb.MethodDescriptor(
    '/proto.ReportService/GetNGReportGoroutineMax',
    grpcWeb.MethodType.UNARY,
    report_pb.NGReportGoroutineMaxRequest,
    report_pb.NGReportGoroutineMaxResponse,
    (request: report_pb.NGReportGoroutineMaxRequest) => {
      return request.serializeBinary();
    },
    report_pb.NGReportGoroutineMaxResponse.deserializeBinary
  );

  getNGReportGoroutineMax(
    request: report_pb.NGReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.NGReportGoroutineMaxResponse>;

  getNGReportGoroutineMax(
    request: report_pb.NGReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.NGReportGoroutineMaxResponse) => void): grpcWeb.ClientReadableStream<report_pb.NGReportGoroutineMaxResponse>;

  getNGReportGoroutineMax(
    request: report_pb.NGReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.NGReportGoroutineMaxResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ReportService/GetNGReportGoroutineMax',
        request,
        metadata || {},
        this.methodDescriptorGetNGReportGoroutineMax,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ReportService/GetNGReportGoroutineMax',
    request,
    metadata || {},
    this.methodDescriptorGetNGReportGoroutineMax);
  }

  methodDescriptorGetMSReportGoroutineMax = new grpcWeb.MethodDescriptor(
    '/proto.ReportService/GetMSReportGoroutineMax',
    grpcWeb.MethodType.UNARY,
    report_pb.MSReportGoroutineMaxRequest,
    report_pb.MSReportGoroutineMaxResponse,
    (request: report_pb.MSReportGoroutineMaxRequest) => {
      return request.serializeBinary();
    },
    report_pb.MSReportGoroutineMaxResponse.deserializeBinary
  );

  getMSReportGoroutineMax(
    request: report_pb.MSReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.MSReportGoroutineMaxResponse>;

  getMSReportGoroutineMax(
    request: report_pb.MSReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.MSReportGoroutineMaxResponse) => void): grpcWeb.ClientReadableStream<report_pb.MSReportGoroutineMaxResponse>;

  getMSReportGoroutineMax(
    request: report_pb.MSReportGoroutineMaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.MSReportGoroutineMaxResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ReportService/GetMSReportGoroutineMax',
        request,
        metadata || {},
        this.methodDescriptorGetMSReportGoroutineMax,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ReportService/GetMSReportGoroutineMax',
    request,
    metadata || {},
    this.methodDescriptorGetMSReportGoroutineMax);
  }

}

