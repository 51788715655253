/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: metric.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as metric_pb from './metric_pb'; // proto import: "metric.proto"


export class DashboardMetricServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListDashboardMetric = new grpcWeb.MethodDescriptor(
    '/proto.DashboardMetricService/ListDashboardMetric',
    grpcWeb.MethodType.UNARY,
    metric_pb.ListDashboardMetricRequest,
    metric_pb.ListDashboardMetricResponse,
    (request: metric_pb.ListDashboardMetricRequest) => {
      return request.serializeBinary();
    },
    metric_pb.ListDashboardMetricResponse.deserializeBinary
  );

  listDashboardMetric(
    request: metric_pb.ListDashboardMetricRequest,
    metadata?: grpcWeb.Metadata | null): Promise<metric_pb.ListDashboardMetricResponse>;

  listDashboardMetric(
    request: metric_pb.ListDashboardMetricRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_pb.ListDashboardMetricResponse) => void): grpcWeb.ClientReadableStream<metric_pb.ListDashboardMetricResponse>;

  listDashboardMetric(
    request: metric_pb.ListDashboardMetricRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_pb.ListDashboardMetricResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardMetricService/ListDashboardMetric',
        request,
        metadata || {},
        this.methodDescriptorListDashboardMetric,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardMetricService/ListDashboardMetric',
    request,
    metadata || {},
    this.methodDescriptorListDashboardMetric);
  }

  methodDescriptorListDashboardMetricWithItems = new grpcWeb.MethodDescriptor(
    '/proto.DashboardMetricService/ListDashboardMetricWithItems',
    grpcWeb.MethodType.UNARY,
    metric_pb.ListDashboardMetricWithItemsRequest,
    metric_pb.ListDashboardMetricWithItemsResponse,
    (request: metric_pb.ListDashboardMetricWithItemsRequest) => {
      return request.serializeBinary();
    },
    metric_pb.ListDashboardMetricWithItemsResponse.deserializeBinary
  );

  listDashboardMetricWithItems(
    request: metric_pb.ListDashboardMetricWithItemsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<metric_pb.ListDashboardMetricWithItemsResponse>;

  listDashboardMetricWithItems(
    request: metric_pb.ListDashboardMetricWithItemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: metric_pb.ListDashboardMetricWithItemsResponse) => void): grpcWeb.ClientReadableStream<metric_pb.ListDashboardMetricWithItemsResponse>;

  listDashboardMetricWithItems(
    request: metric_pb.ListDashboardMetricWithItemsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: metric_pb.ListDashboardMetricWithItemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardMetricService/ListDashboardMetricWithItems',
        request,
        metadata || {},
        this.methodDescriptorListDashboardMetricWithItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardMetricService/ListDashboardMetricWithItems',
    request,
    metadata || {},
    this.methodDescriptorListDashboardMetricWithItems);
  }

}

