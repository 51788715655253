/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: events.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as events_pb from './events_pb';


export class EventServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListEvent = new grpcWeb.MethodDescriptor(
    '/proto.EventService/ListEvent',
    grpcWeb.MethodType.UNARY,
    events_pb.ListEventRequest,
    events_pb.ListEventResponse,
    (request: events_pb.ListEventRequest) => {
      return request.serializeBinary();
    },
    events_pb.ListEventResponse.deserializeBinary
  );

  listEvent(
    request: events_pb.ListEventRequest,
    metadata: grpcWeb.Metadata | null): Promise<events_pb.ListEventResponse>;

  listEvent(
    request: events_pb.ListEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: events_pb.ListEventResponse) => void): grpcWeb.ClientReadableStream<events_pb.ListEventResponse>;

  listEvent(
    request: events_pb.ListEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: events_pb.ListEventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.EventService/ListEvent',
        request,
        metadata || {},
        this.methodDescriptorListEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.EventService/ListEvent',
    request,
    metadata || {},
    this.methodDescriptorListEvent);
  }

  methodDescriptorCountEvent = new grpcWeb.MethodDescriptor(
    '/proto.EventService/CountEvent',
    grpcWeb.MethodType.UNARY,
    events_pb.ListEventRequest,
    events_pb.CountEventResponse,
    (request: events_pb.ListEventRequest) => {
      return request.serializeBinary();
    },
    events_pb.CountEventResponse.deserializeBinary
  );

  countEvent(
    request: events_pb.ListEventRequest,
    metadata: grpcWeb.Metadata | null): Promise<events_pb.CountEventResponse>;

  countEvent(
    request: events_pb.ListEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: events_pb.CountEventResponse) => void): grpcWeb.ClientReadableStream<events_pb.CountEventResponse>;

  countEvent(
    request: events_pb.ListEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: events_pb.CountEventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.EventService/CountEvent',
        request,
        metadata || {},
        this.methodDescriptorCountEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.EventService/CountEvent',
    request,
    metadata || {},
    this.methodDescriptorCountEvent);
  }

  methodDescriptorLastEvent = new grpcWeb.MethodDescriptor(
    '/proto.EventService/LastEvent',
    grpcWeb.MethodType.UNARY,
    events_pb.LastEventRequest,
    events_pb.LastEventResponse,
    (request: events_pb.LastEventRequest) => {
      return request.serializeBinary();
    },
    events_pb.LastEventResponse.deserializeBinary
  );

  lastEvent(
    request: events_pb.LastEventRequest,
    metadata: grpcWeb.Metadata | null): Promise<events_pb.LastEventResponse>;

  lastEvent(
    request: events_pb.LastEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: events_pb.LastEventResponse) => void): grpcWeb.ClientReadableStream<events_pb.LastEventResponse>;

  lastEvent(
    request: events_pb.LastEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: events_pb.LastEventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.EventService/LastEvent',
        request,
        metadata || {},
        this.methodDescriptorLastEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.EventService/LastEvent',
    request,
    metadata || {},
    this.methodDescriptorLastEvent);
  }

  methodDescriptorExportListEvent = new grpcWeb.MethodDescriptor(
    '/proto.EventService/ExportListEvent',
    grpcWeb.MethodType.UNARY,
    events_pb.ListEventRequest,
    events_pb.ExportListEventResponse,
    (request: events_pb.ListEventRequest) => {
      return request.serializeBinary();
    },
    events_pb.ExportListEventResponse.deserializeBinary
  );

  exportListEvent(
    request: events_pb.ListEventRequest,
    metadata: grpcWeb.Metadata | null): Promise<events_pb.ExportListEventResponse>;

  exportListEvent(
    request: events_pb.ListEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: events_pb.ExportListEventResponse) => void): grpcWeb.ClientReadableStream<events_pb.ExportListEventResponse>;

  exportListEvent(
    request: events_pb.ListEventRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: events_pb.ExportListEventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.EventService/ExportListEvent',
        request,
        metadata || {},
        this.methodDescriptorExportListEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.EventService/ExportListEvent',
    request,
    metadata || {},
    this.methodDescriptorExportListEvent);
  }

  methodDescriptorListEventSource = new grpcWeb.MethodDescriptor(
    '/proto.EventService/ListEventSource',
    grpcWeb.MethodType.UNARY,
    events_pb.ListEventSourceRequest,
    events_pb.ListEventSourceResponse,
    (request: events_pb.ListEventSourceRequest) => {
      return request.serializeBinary();
    },
    events_pb.ListEventSourceResponse.deserializeBinary
  );

  listEventSource(
    request: events_pb.ListEventSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<events_pb.ListEventSourceResponse>;

  listEventSource(
    request: events_pb.ListEventSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: events_pb.ListEventSourceResponse) => void): grpcWeb.ClientReadableStream<events_pb.ListEventSourceResponse>;

  listEventSource(
    request: events_pb.ListEventSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: events_pb.ListEventSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.EventService/ListEventSource',
        request,
        metadata || {},
        this.methodDescriptorListEventSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.EventService/ListEventSource',
    request,
    metadata || {},
    this.methodDescriptorListEventSource);
  }

  methodDescriptorCountEventSource = new grpcWeb.MethodDescriptor(
    '/proto.EventService/CountEventSource',
    grpcWeb.MethodType.UNARY,
    events_pb.ListEventSourceRequest,
    events_pb.CountEventSourceResponse,
    (request: events_pb.ListEventSourceRequest) => {
      return request.serializeBinary();
    },
    events_pb.CountEventSourceResponse.deserializeBinary
  );

  countEventSource(
    request: events_pb.ListEventSourceRequest,
    metadata: grpcWeb.Metadata | null): Promise<events_pb.CountEventSourceResponse>;

  countEventSource(
    request: events_pb.ListEventSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: events_pb.CountEventSourceResponse) => void): grpcWeb.ClientReadableStream<events_pb.CountEventSourceResponse>;

  countEventSource(
    request: events_pb.ListEventSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: events_pb.CountEventSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.EventService/CountEventSource',
        request,
        metadata || {},
        this.methodDescriptorCountEventSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.EventService/CountEventSource',
    request,
    metadata || {},
    this.methodDescriptorCountEventSource);
  }

}

