import { DashboardPointServiceClient } from '../../proto/pb/PointServiceClientPb'
import {
  ListDashboardPointEarnedPointsRequest,
  ListDashboardPointEarnedGroupByRequest,
  ListDashboardPointActivListRequest,
  ListDashboardPointEarnedChartRequest,
  GetDashboardPointEarnedByProjectRequest,
  GetDashboardPointMinMaxDateRequest,
  DashboardPointActiv,
  DashboardPointFilter,
  DashboardPointFilterItem,
  DashboardPointSort,
  DashboardPointPagination,
} from '../../proto/pb/point_pb'
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class PointApi {
  client: DashboardPointServiceClient
  metadata: any

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new DashboardPointServiceClient(
      host,
      null,
      { unaryInterceptors: [new RefreshTokenInterceptor()] }
    )
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  createSort(data: any) {
    const sort = new DashboardPointSort()
    const name: string =
      data.name !== undefined ? data.name.toString() : ''
    const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc'

    // Устанавливаем параметры сортировки
    sort.setName(name)
    sort.setExp(exp)

    return sort
  }

  createFilter(data: any) {
    const filter = new DashboardPointFilter()
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && '' !== data[i].field_name) {
          const fItem = new DashboardPointFilterItem()
          fItem.setFieldName(data[i].field_name.toString())
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand : '='
          )
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ''
          )
          fItem.setOr(this.createFilter(data[i].or)) // Рекурсия !!!
          filter.addItems(fItem)
        }
      }
    }
    return filter
  }

  createPagination(data: any) {
    const pagination = new DashboardPointPagination()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : 1000
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages).setCnt(cnt)

    return pagination
  }

  getPointsByProject(data: any) {
    const req = new GetDashboardPointEarnedByProjectRequest()
    req
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)

    const resArr: DashboardPointActiv[] = []

    data.activ_ids.forEach((el: any) => {
      const object = new DashboardPointActiv()

      object
        .setActivId(el.activ_id)
        .setActivText(el.activ_text)
        .setProjectId(el.project_id)

      resArr.push(object)
    })

    req
      .setActivIdsList(resArr)

    return this.client.listDashboardPointEarnedByProject(req, this.metadata)
  }

  getCityEarnedChart(data: any) {
    const req = new ListDashboardPointEarnedChartRequest()
    req
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setInterval(data.interval)

    const resArr: DashboardPointActiv[] = []

    data.activ_ids.forEach((el: any) => {
      const object = new DashboardPointActiv()

      object
        .setActivId(el.activ_id)
        .setActivText(el.activ_text)
        .setProjectId(el.project_id)

      resArr.push(object)
    })

    req
      .setActivIdsList(resArr)
    
    console.log('require', req.toObject());
    
    
    return this.client.listDashboardPointEarnedChart(req, this.metadata)
  }

  getCityActions(data: any) {
    const req = new ListDashboardPointActivListRequest()
    req
      .setProjectIdsList(data.project_ids)

    return this.client.listDashboardPointActivList(req, this.metadata)
  }

  getPointsTable(filter: any, sort: any, pagination: any, data: any) {
    const req = new ListDashboardPointEarnedPointsRequest()
    req
      .setFilter(this.createFilter(filter))
      .setSort(this.createSort(sort))
      .setPagination(this.createPagination(pagination))
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)

    console.log('%c[DEBUG]:Get points table req', 'color:violet', req.toObject())

    const resArr: DashboardPointActiv[] = []

    data.activ_ids.forEach((el: any) => {
      const object = new DashboardPointActiv()

      object
        .setActivId(el.activ_id)
        .setActivText(el.activ_text)
        .setProjectId(el.project_id)

      resArr.push(object)
    })

    req
      .setActivIdsList(resArr)

    return this.client.listDashboardPointEarnedPoints(req, this.metadata)
  }

  getPointsGroupTable(filter: any, sort: any, pagination: any, data: any) {
    const req = new ListDashboardPointEarnedGroupByRequest()
    req
      .setFilter(this.createFilter(filter))
      .setSort(this.createSort(sort))
      .setPagination(this.createPagination(pagination))
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)
      .setGroupBy(data.group_by)

    const resArr: DashboardPointActiv[] = []

    data.activ_ids.forEach((el: any) => {
      const object = new DashboardPointActiv()

      object
        .setActivId(el.activ_id)
        .setActivText(el.activ_text)
        .setProjectId(el.project_id)

      resArr.push(object)
    })

    req
      .setActivIdsList(resArr)

    console.log('group req', req.toObject())

    return this.client.listDashboardPointEarnedGroupBy(req, this.metadata)
  }

  getExportPointsTable(filter: any, sort: any, pagination: any, data: any) {
    const req = new ListDashboardPointEarnedPointsRequest()

    req
      .setFilter(this.createFilter(filter))
      .setSort(this.createSort(sort))
      .setPagination(this.createPagination(pagination))
      .setActivityIdsList(data.activity)
      .setLoyaltyIdsList(data.loyalty)
      .setUsefulnessIdsList(data.useful)
      .setInterestIdsList(data.interest)
      .setDateBegin(data.date_begin)
      .setDateEnd(data.date_end)

    const resArr: DashboardPointActiv[] = []

    data.activ_ids.forEach((el: any) => {
      const object = new DashboardPointActiv()

      object
        .setActivId(el.activ_id)
        .setActivText(el.activ_text)
        .setProjectId(el.project_id)

      resArr.push(object)
    })

    req
      .setActivIdsList(resArr)

    return this.client.exportListDashboardPointEarnedPoints(req, this.metadata)
  }

  getMinMaxPointDate(data: any) {
    const req = new GetDashboardPointMinMaxDateRequest()

    const resArr: DashboardPointActiv[] = []

    data.activ_ids.forEach((el: any) => {
      const object = new DashboardPointActiv()

      object
        .setActivId(el.activ_id)
        .setActivText(el.activ_text)
        .setProjectId(el.project_id)

      resArr.push(object)
    })

    req.setActivIdsList(resArr)

    return this.client.getDashboardPointMinMaxDate(req, this.metadata)
  }
}
