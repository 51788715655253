import { PollLoadServiceClient } from "../../proto/pb/Poll_loadServiceClientPb";
import {
  PollLoadFilter,
  PollLoadFilterItem,
  PollLoadSort,
  PollLoadPagination,
  ListPollLoadRequest,
  LoadPollLoadRequest,
  CheckPollLoadRequest,
  GetPollLoadVotesByDateRequest,
  ListPollLoadAccountsRequest,
  GetPollLoadAnswersRequest,
  ListPollLoadAnswerAccountsRequest,
  PollSourcesDistrChartRequest,
} from "../../proto/pb/poll_load_pb";
import {
  Filter,
  FilterItem,
  Pagination,
  Sort
} from "../../proto/pb/filter_pb"
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000;

export class LoadPolls {
  client: PollLoadServiceClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? "";
    this.client = new PollLoadServiceClient(host, null, {
      unaryInterceptors: [new RefreshTokenInterceptor()],
    });
    this.metadata = {
      Authorization: localStorage.getItem("access-key"),
    };
  }

  createFilter(data: any) {
    const filter = new PollLoadFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && "" !== data[i].field_name) {
          const fItem = new PollLoadFilterItem();
          fItem.setFieldName(data[i].field_name.toString());
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand.toString() : "="
          );
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ""
          );
          fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
          filter.addItems(fItem);
        }

        //TODO: Will be removed after global refactor. currently was made when using Type.asObject for request
        // current function was made by hand and filters doesn't have "field_name" property instead it should've been
        // "fieldName" but proper type was not set and here we have a collapse of proper usage and made by hand "type"
        if (data[i].fieldName) {
          const fItem = new PollLoadFilterItem();
          fItem
            .setFieldName(data[i].fieldName)
            .setOperand(data[i].operand || "=")
            .setValue(data[i].value || "");

          if (data[i]?.or?.itemsList?.length) {
            fItem.setOr(this.createFilter(data[i].or.itemsList || []));
          } else {
            fItem.setOr(new PollLoadFilter());
          }

          filter.addItems(fItem);
        }
      }
    }

    return filter;
  }

  createSort(data: any) {
    const sort = new PollLoadSort();
    const name: string = data.name !== undefined ? data.name.toString() : "";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "asc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  createPagination(data: any) {
    const pagination = new PollLoadPagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page)
    .setLimit(limit)
    .setPages(pages);

    return pagination;
  }

  createNewFilter(data: any) {
    const filter = new Filter();

    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && "" !== data[i].field_name) {
          const fItem = new FilterItem();
          fItem.setFieldName(data[i].field_name.toString());
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand.toString() : "="
          );
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ""
          );
          fItem.setOr(this.createNewFilter(data[i].or)); // Рекурсия !!!
          filter.addItems(fItem);
        }

        //TODO: Will be removed after global refactor. currently was made when using Type.asObject for request
        // current function was made by hand and filters doesn't have "field_name" property instead it should've been
        // "fieldName" but proper type was not set and here we have a collapse of proper usage and made by hand "type"
        if (data[i].fieldName) {
          const fItem = new FilterItem();
          fItem
            .setFieldName(data[i].fieldName)
            .setOperand(data[i].operand || "=")
            .setValue(data[i].value || "");

          if (data[i]?.or?.itemsList?.length) {
            fItem.setOr(this.createNewFilter(data[i].or.itemsList || []));
          } else {
            fItem.setOr(new Filter());
          }

          filter.addItems(fItem);
        }
      }
    }

    return filter;
  }

  createNewSort(data: any) {
    const sort = new Sort();
    const name: string = data !== undefined && data?.name !== undefined ? data.name.toString() : "";
    const exp: string = data !== undefined && data?.exp !== undefined ? data.exp.toString() : "asc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  createNewPagination(data: any) {
    const pagination = new Pagination();
    const page: number = data?.page !== undefined ? Number(data.page) : 1;
    const limit: number = data?.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data?.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data?.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page)
      .setLimit(limit)
      .setPages(pages)
      .setCnt(cnt)

    return pagination;
  }

  getPollLoadItems(filter: any, sort: any, pagination: any) {
    const request = new ListPollLoadRequest();

    // Устанавливаем параметры запроса
    request
      .setFilter(this.createFilter(filter))
      .setSort(this.createSort(sort))
      .setPagination(this.createPagination(pagination));

    console.log("LOAD POLL ITEMS REQ", request.toObject());

    return this.client.listPollLoad(request, this.metadata)
  }
  getPollLoadChildren(input: ListPollLoadRequest.AsObject) {
    const req = new ListPollLoadRequest();
    req
      .setFilter(this.createFilter(input.filter?.itemsList))
      .setPagination(this.createPagination(input.pagination))
      .setSort(this.createSort(input.sort));

    console.log(
      "%c[API]:getPollLoadChildren request",
      "color:lightblue",
      req.toObject()
    );
    return this.client.listPollLoadChildren(req, this.metadata);
  }
  getPollLoad(poll_id: any) {
    const request = new LoadPollLoadRequest();

    request.setPollId(poll_id);

    console.log("POLL LOAD REQ", request.toObject());

    return this.client.loadPollLoad(request, this.metadata);
  }

  checkPollLoad() {
    const request = new CheckPollLoadRequest();

    return this.client.checkPollLoad(request, this.metadata);
  }

  getPollsByDate(data: any) {
    const request = new GetPollLoadVotesByDateRequest();

    // Устанавливаем параметры запроса
    request.setPollId(data.poll_id);

    console.log("date poll req", request.toObject());

    // return this.client.listPollLoad(request, this.metadata)

    return this.client.getPollLoadVotesByDate(request, this.metadata);
  }

  getVoteUsers(filter: any, pagination: any, sort: any, data: any) {
    const request = new ListPollLoadAccountsRequest();

    request
      .setFilter(this.createNewFilter(filter))
      .setPagination(this.createNewPagination(pagination))
      .setSort(this.createNewSort(sort))
      .setPollId(data.poll_id);

    console.log("vote users req", request.toObject());

    return this.client.listPollLoadAccounts(request, this.metadata).then((res) => {
      console.log('vote users response', res.toObject());

      return res

    })
  }

  getPersonalPollLoadItems(filter: any, pagination: any, sort: any, poll_id: number) {
    const request = new ListPollLoadAccountsRequest();

    // Устанавливаем параметры запроса
    request
      .setFilter(this.createNewFilter(filter))
      .setPagination(this.createNewPagination(pagination))
      .setSort(this.createNewSort(sort))
      .setPollId(poll_id);

    console.log("LOAD POLL PERSONAL ITEMS REQ", request.toObject());

    // return this.client.listPollLoad(request, this.metadata)

    return this.client.personalListPollLoadAccounts(request, this.metadata);
  }

  getPollLoadAnswers(poll_id: number) {
    const request = new GetPollLoadAnswersRequest();

    request.setPollId(poll_id);

    return this.client.getPollLoadAnswers(request, this.metadata);
  }

  getAnswerAccounts(pagination: any, answer_id: number) {
    const request = new ListPollLoadAnswerAccountsRequest();

    request
      .setPagination(this.createPagination(pagination))
      .setAnswerId(answer_id);

    console.log("answer accs req", request.toObject());

    return this.client.listPollLoadAnswerAccounts(request, this.metadata);
  }

  getPersonalAnswerAccounts(pagination: any, answer_id: number) {
    const request = new ListPollLoadAnswerAccountsRequest();

    request
      .setPagination(this.createPagination(pagination))
      .setAnswerId(answer_id);

    console.log("answer accs req", request.toObject());

    return this.client.personalListPollLoadAnswerAccounts(request, this.metadata);
  }

  getPollSourceChart(poll_id: number) {
    const request = new PollSourcesDistrChartRequest()

    request.setPollId(poll_id)

    console.log('poll source req', request.toObject());

    return this.client.pollSourcesDistrChart(request, this.metadata).then((res) => {
      console.log('poll source res', res.toObject());
      return res
    });
  }
}
