// source: point.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var filter_pb = require('./filter_pb.js');
goog.object.extend(proto, filter_pb);
goog.exportSymbol('proto.proto.CountPoint', null, global);
goog.exportSymbol('proto.proto.CountPointEarnedByProjectRequest', null, global);
goog.exportSymbol('proto.proto.CountPointEarnedByProjectResponse', null, global);
goog.exportSymbol('proto.proto.CountPointRequest', null, global);
goog.exportSymbol('proto.proto.CountPointResponse', null, global);
goog.exportSymbol('proto.proto.CountPointSegmentRequest', null, global);
goog.exportSymbol('proto.proto.CountPointSegmentResponse', null, global);
goog.exportSymbol('proto.proto.ExportListPointResponse', null, global);
goog.exportSymbol('proto.proto.ListPointBalanceSheduleRequest', null, global);
goog.exportSymbol('proto.proto.ListPointBalanceSheduleResponse', null, global);
goog.exportSymbol('proto.proto.ListPointEarnedRequest', null, global);
goog.exportSymbol('proto.proto.ListPointEarnedResponse', null, global);
goog.exportSymbol('proto.proto.ListPointEarnedSheduleRequest', null, global);
goog.exportSymbol('proto.proto.ListPointEarnedSheduleResponse', null, global);
goog.exportSymbol('proto.proto.ListPointSpentRequest', null, global);
goog.exportSymbol('proto.proto.ListPointSpentResponse', null, global);
goog.exportSymbol('proto.proto.ListPointSpentSheduleRequest', null, global);
goog.exportSymbol('proto.proto.ListPointSpentSheduleResponse', null, global);
goog.exportSymbol('proto.proto.ListPointTypesRequest', null, global);
goog.exportSymbol('proto.proto.ListPointTypesResponse', null, global);
goog.exportSymbol('proto.proto.PointEvent', null, global);
goog.exportSymbol('proto.proto.PointShedule', null, global);
goog.exportSymbol('proto.proto.PointType', null, global);
goog.exportSymbol('proto.proto.ProjectCount', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PointEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PointEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PointEvent.displayName = 'proto.proto.PointEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointEarnedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPointEarnedRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPointEarnedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointEarnedRequest.displayName = 'proto.proto.ListPointEarnedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointEarnedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPointEarnedResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPointEarnedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointEarnedResponse.displayName = 'proto.proto.ListPointEarnedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointSpentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPointSpentRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPointSpentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointSpentRequest.displayName = 'proto.proto.ListPointSpentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointSpentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPointSpentResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPointSpentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointSpentResponse.displayName = 'proto.proto.ListPointSpentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PointShedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PointShedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PointShedule.displayName = 'proto.proto.PointShedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointEarnedSheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPointEarnedSheduleRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPointEarnedSheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointEarnedSheduleRequest.displayName = 'proto.proto.ListPointEarnedSheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointEarnedSheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPointEarnedSheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPointEarnedSheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointEarnedSheduleResponse.displayName = 'proto.proto.ListPointEarnedSheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointSpentSheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListPointSpentSheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointSpentSheduleRequest.displayName = 'proto.proto.ListPointSpentSheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointSpentSheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPointSpentSheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPointSpentSheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointSpentSheduleResponse.displayName = 'proto.proto.ListPointSpentSheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointBalanceSheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListPointBalanceSheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointBalanceSheduleRequest.displayName = 'proto.proto.ListPointBalanceSheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointBalanceSheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPointBalanceSheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPointBalanceSheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointBalanceSheduleResponse.displayName = 'proto.proto.ListPointBalanceSheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CountPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CountPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CountPoint.displayName = 'proto.proto.CountPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CountPointRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CountPointRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.CountPointRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CountPointRequest.displayName = 'proto.proto.CountPointRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CountPointResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CountPointResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.CountPointResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CountPointResponse.displayName = 'proto.proto.CountPointResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CountPointSegmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CountPointSegmentRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.CountPointSegmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CountPointSegmentRequest.displayName = 'proto.proto.CountPointSegmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CountPointSegmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CountPointSegmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CountPointSegmentResponse.displayName = 'proto.proto.CountPointSegmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ProjectCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ProjectCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ProjectCount.displayName = 'proto.proto.ProjectCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CountPointEarnedByProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CountPointEarnedByProjectRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.CountPointEarnedByProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CountPointEarnedByProjectRequest.displayName = 'proto.proto.CountPointEarnedByProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CountPointEarnedByProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CountPointEarnedByProjectResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.CountPointEarnedByProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CountPointEarnedByProjectResponse.displayName = 'proto.proto.CountPointEarnedByProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ExportListPointResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ExportListPointResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ExportListPointResponse.displayName = 'proto.proto.ExportListPointResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PointType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PointType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PointType.displayName = 'proto.proto.PointType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListPointTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointTypesRequest.displayName = 'proto.proto.ListPointTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPointTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPointTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPointTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPointTypesResponse.displayName = 'proto.proto.ListPointTypesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PointEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PointEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PointEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PointEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pointId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pointName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pointDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pointDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pointValue: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pointTypeId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pointType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    pointVoteSourceId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pointVoteSource: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PointEvent}
 */
proto.proto.PointEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PointEvent;
  return proto.proto.PointEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PointEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PointEvent}
 */
proto.proto.PointEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointDate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointValue(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointTypeId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointVoteSourceId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointVoteSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PointEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PointEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PointEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PointEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPointId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPointName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPointDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPointDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPointValue();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPointTypeId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPointType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPointVoteSourceId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getPointVoteSource();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int64 project_id = 1;
 * @return {number}
 */
proto.proto.PointEvent.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string project_name = 2;
 * @return {string}
 */
proto.proto.PointEvent.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 point_id = 3;
 * @return {number}
 */
proto.proto.PointEvent.prototype.getPointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setPointId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string point_name = 4;
 * @return {string}
 */
proto.proto.PointEvent.prototype.getPointName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setPointName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string point_description = 5;
 * @return {string}
 */
proto.proto.PointEvent.prototype.getPointDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setPointDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string point_date = 6;
 * @return {string}
 */
proto.proto.PointEvent.prototype.getPointDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setPointDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 point_value = 7;
 * @return {number}
 */
proto.proto.PointEvent.prototype.getPointValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setPointValue = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 point_type_id = 8;
 * @return {number}
 */
proto.proto.PointEvent.prototype.getPointTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setPointTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string point_type = 9;
 * @return {string}
 */
proto.proto.PointEvent.prototype.getPointType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setPointType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 point_vote_source_id = 10;
 * @return {number}
 */
proto.proto.PointEvent.prototype.getPointVoteSourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setPointVoteSourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string point_vote_source = 11;
 * @return {string}
 */
proto.proto.PointEvent.prototype.getPointVoteSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointEvent} returns this
 */
proto.proto.PointEvent.prototype.setPointVoteSource = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPointEarnedRequest.repeatedFields_ = [2,3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointEarnedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointEarnedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointEarnedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointEarnedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pointTypeIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    sort: (f = msg.getSort()) && filter_pb.Sort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && filter_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointEarnedRequest}
 */
proto.proto.ListPointEarnedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointEarnedRequest;
  return proto.proto.ListPointEarnedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointEarnedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointEarnedRequest}
 */
proto.proto.ListPointEarnedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPointTypeIds(values[i]);
      }
      break;
    case 7:
      var value = new filter_pb.Sort;
      reader.readMessage(value,filter_pb.Sort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 8:
      var value = new filter_pb.Pagination;
      reader.readMessage(value,filter_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointEarnedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointEarnedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointEarnedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointEarnedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPointTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      filter_pb.Sort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      filter_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.ListPointEarnedRequest.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 project_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.ListPointEarnedRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * repeated int64 event_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListPointEarnedRequest.prototype.getEventIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};


/**
 * optional string date_begin = 4;
 * @return {string}
 */
proto.proto.ListPointEarnedRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date_end = 5;
 * @return {string}
 */
proto.proto.ListPointEarnedRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated int64 point_type_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListPointEarnedRequest.prototype.getPointTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.setPointTypeIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.addPointTypeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.clearPointTypeIdsList = function() {
  return this.setPointTypeIdsList([]);
};


/**
 * optional Sort sort = 7;
 * @return {?proto.proto.Sort}
 */
proto.proto.ListPointEarnedRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.Sort} */ (
    jspb.Message.getWrapperField(this, filter_pb.Sort, 7));
};


/**
 * @param {?proto.proto.Sort|undefined} value
 * @return {!proto.proto.ListPointEarnedRequest} returns this
*/
proto.proto.ListPointEarnedRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPointEarnedRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Pagination pagination = 8;
 * @return {?proto.proto.Pagination}
 */
proto.proto.ListPointEarnedRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, filter_pb.Pagination, 8));
};


/**
 * @param {?proto.proto.Pagination|undefined} value
 * @return {!proto.proto.ListPointEarnedRequest} returns this
*/
proto.proto.ListPointEarnedRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPointEarnedRequest} returns this
 */
proto.proto.ListPointEarnedRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPointEarnedRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPointEarnedResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointEarnedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointEarnedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointEarnedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointEarnedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PointEvent.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListPointEarnedRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointEarnedResponse}
 */
proto.proto.ListPointEarnedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointEarnedResponse;
  return proto.proto.ListPointEarnedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointEarnedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointEarnedResponse}
 */
proto.proto.ListPointEarnedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PointEvent;
      reader.readMessage(value,proto.proto.PointEvent.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListPointEarnedRequest;
      reader.readMessage(value,proto.proto.ListPointEarnedRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointEarnedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointEarnedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointEarnedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointEarnedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PointEvent.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListPointEarnedRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointEvent items = 1;
 * @return {!Array<!proto.proto.PointEvent>}
 */
proto.proto.ListPointEarnedResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PointEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PointEvent, 1));
};


/**
 * @param {!Array<!proto.proto.PointEvent>} value
 * @return {!proto.proto.ListPointEarnedResponse} returns this
*/
proto.proto.ListPointEarnedResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PointEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PointEvent}
 */
proto.proto.ListPointEarnedResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PointEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointEarnedResponse} returns this
 */
proto.proto.ListPointEarnedResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListPointEarnedRequest params = 2;
 * @return {?proto.proto.ListPointEarnedRequest}
 */
proto.proto.ListPointEarnedResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListPointEarnedRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListPointEarnedRequest, 2));
};


/**
 * @param {?proto.proto.ListPointEarnedRequest|undefined} value
 * @return {!proto.proto.ListPointEarnedResponse} returns this
*/
proto.proto.ListPointEarnedResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPointEarnedResponse} returns this
 */
proto.proto.ListPointEarnedResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPointEarnedResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPointSpentRequest.repeatedFields_ = [2,3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointSpentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointSpentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointSpentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointSpentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pointTypeIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    sort: (f = msg.getSort()) && filter_pb.Sort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && filter_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointSpentRequest}
 */
proto.proto.ListPointSpentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointSpentRequest;
  return proto.proto.ListPointSpentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointSpentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointSpentRequest}
 */
proto.proto.ListPointSpentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPointTypeIds(values[i]);
      }
      break;
    case 7:
      var value = new filter_pb.Sort;
      reader.readMessage(value,filter_pb.Sort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 8:
      var value = new filter_pb.Pagination;
      reader.readMessage(value,filter_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointSpentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointSpentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointSpentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointSpentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPointTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      filter_pb.Sort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      filter_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.ListPointSpentRequest.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 project_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.ListPointSpentRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * repeated int64 event_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListPointSpentRequest.prototype.getEventIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};


/**
 * optional string date_begin = 4;
 * @return {string}
 */
proto.proto.ListPointSpentRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date_end = 5;
 * @return {string}
 */
proto.proto.ListPointSpentRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated int64 point_type_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListPointSpentRequest.prototype.getPointTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.setPointTypeIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.addPointTypeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.clearPointTypeIdsList = function() {
  return this.setPointTypeIdsList([]);
};


/**
 * optional Sort sort = 7;
 * @return {?proto.proto.Sort}
 */
proto.proto.ListPointSpentRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.Sort} */ (
    jspb.Message.getWrapperField(this, filter_pb.Sort, 7));
};


/**
 * @param {?proto.proto.Sort|undefined} value
 * @return {!proto.proto.ListPointSpentRequest} returns this
*/
proto.proto.ListPointSpentRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPointSpentRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Pagination pagination = 8;
 * @return {?proto.proto.Pagination}
 */
proto.proto.ListPointSpentRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, filter_pb.Pagination, 8));
};


/**
 * @param {?proto.proto.Pagination|undefined} value
 * @return {!proto.proto.ListPointSpentRequest} returns this
*/
proto.proto.ListPointSpentRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPointSpentRequest} returns this
 */
proto.proto.ListPointSpentRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPointSpentRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPointSpentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointSpentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointSpentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointSpentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointSpentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PointEvent.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListPointSpentRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointSpentResponse}
 */
proto.proto.ListPointSpentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointSpentResponse;
  return proto.proto.ListPointSpentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointSpentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointSpentResponse}
 */
proto.proto.ListPointSpentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PointEvent;
      reader.readMessage(value,proto.proto.PointEvent.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListPointSpentRequest;
      reader.readMessage(value,proto.proto.ListPointSpentRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointSpentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointSpentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointSpentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointSpentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PointEvent.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListPointSpentRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointEvent items = 1;
 * @return {!Array<!proto.proto.PointEvent>}
 */
proto.proto.ListPointSpentResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PointEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PointEvent, 1));
};


/**
 * @param {!Array<!proto.proto.PointEvent>} value
 * @return {!proto.proto.ListPointSpentResponse} returns this
*/
proto.proto.ListPointSpentResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PointEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PointEvent}
 */
proto.proto.ListPointSpentResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PointEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointSpentResponse} returns this
 */
proto.proto.ListPointSpentResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListPointSpentRequest params = 2;
 * @return {?proto.proto.ListPointSpentRequest}
 */
proto.proto.ListPointSpentResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListPointSpentRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListPointSpentRequest, 2));
};


/**
 * @param {?proto.proto.ListPointSpentRequest|undefined} value
 * @return {!proto.proto.ListPointSpentResponse} returns this
*/
proto.proto.ListPointSpentResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPointSpentResponse} returns this
 */
proto.proto.ListPointSpentResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPointSpentResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PointShedule.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PointShedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PointShedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PointShedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointDay: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pointMonth: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pointYear: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pointValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    pointCity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    pointPrize: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PointShedule}
 */
proto.proto.PointShedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PointShedule;
  return proto.proto.PointShedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PointShedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PointShedule}
 */
proto.proto.PointShedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointDay(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointMonth(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPointValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPointCity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPointPrize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PointShedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PointShedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PointShedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PointShedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPointMonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPointYear();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPointValue();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getPointCity();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getPointPrize();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string point_day = 1;
 * @return {string}
 */
proto.proto.PointShedule.prototype.getPointDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointShedule} returns this
 */
proto.proto.PointShedule.prototype.setPointDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string point_month = 2;
 * @return {string}
 */
proto.proto.PointShedule.prototype.getPointMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointShedule} returns this
 */
proto.proto.PointShedule.prototype.setPointMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string point_year = 3;
 * @return {string}
 */
proto.proto.PointShedule.prototype.getPointYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointShedule} returns this
 */
proto.proto.PointShedule.prototype.setPointYear = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float point_value = 4;
 * @return {number}
 */
proto.proto.PointShedule.prototype.getPointValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PointShedule} returns this
 */
proto.proto.PointShedule.prototype.setPointValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float point_city = 5;
 * @return {number}
 */
proto.proto.PointShedule.prototype.getPointCity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PointShedule} returns this
 */
proto.proto.PointShedule.prototype.setPointCity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float point_prize = 6;
 * @return {number}
 */
proto.proto.PointShedule.prototype.getPointPrize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PointShedule} returns this
 */
proto.proto.PointShedule.prototype.setPointPrize = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPointEarnedSheduleRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointEarnedSheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointEarnedSheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointEarnedSheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    actionNamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointEarnedSheduleRequest}
 */
proto.proto.ListPointEarnedSheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointEarnedSheduleRequest;
  return proto.proto.ListPointEarnedSheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointEarnedSheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointEarnedSheduleRequest}
 */
proto.proto.ListPointEarnedSheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addActionNames(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointEarnedSheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointEarnedSheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointEarnedSheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getActionNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 project_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * repeated string action_names = 3;
 * @return {!Array<string>}
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.getActionNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.setActionNamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.addActionNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.clearActionNamesList = function() {
  return this.setActionNamesList([]);
};


/**
 * optional string date_begin = 4;
 * @return {string}
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date_end = 5;
 * @return {string}
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string mode = 6;
 * @return {string}
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.getMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointEarnedSheduleRequest} returns this
 */
proto.proto.ListPointEarnedSheduleRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPointEarnedSheduleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointEarnedSheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointEarnedSheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointEarnedSheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointEarnedSheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PointShedule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointEarnedSheduleResponse}
 */
proto.proto.ListPointEarnedSheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointEarnedSheduleResponse;
  return proto.proto.ListPointEarnedSheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointEarnedSheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointEarnedSheduleResponse}
 */
proto.proto.ListPointEarnedSheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PointShedule;
      reader.readMessage(value,proto.proto.PointShedule.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointEarnedSheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointEarnedSheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointEarnedSheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointEarnedSheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PointShedule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointShedule items = 1;
 * @return {!Array<!proto.proto.PointShedule>}
 */
proto.proto.ListPointEarnedSheduleResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PointShedule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PointShedule, 1));
};


/**
 * @param {!Array<!proto.proto.PointShedule>} value
 * @return {!proto.proto.ListPointEarnedSheduleResponse} returns this
*/
proto.proto.ListPointEarnedSheduleResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PointShedule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PointShedule}
 */
proto.proto.ListPointEarnedSheduleResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PointShedule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointEarnedSheduleResponse} returns this
 */
proto.proto.ListPointEarnedSheduleResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointSpentSheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointSpentSheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointSpentSheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointSpentSheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointSpentSheduleRequest}
 */
proto.proto.ListPointSpentSheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointSpentSheduleRequest;
  return proto.proto.ListPointSpentSheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointSpentSheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointSpentSheduleRequest}
 */
proto.proto.ListPointSpentSheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointSpentSheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointSpentSheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointSpentSheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointSpentSheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.ListPointSpentSheduleRequest.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointSpentSheduleRequest} returns this
 */
proto.proto.ListPointSpentSheduleRequest.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_begin = 2;
 * @return {string}
 */
proto.proto.ListPointSpentSheduleRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointSpentSheduleRequest} returns this
 */
proto.proto.ListPointSpentSheduleRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date_end = 3;
 * @return {string}
 */
proto.proto.ListPointSpentSheduleRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointSpentSheduleRequest} returns this
 */
proto.proto.ListPointSpentSheduleRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mode = 4;
 * @return {string}
 */
proto.proto.ListPointSpentSheduleRequest.prototype.getMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointSpentSheduleRequest} returns this
 */
proto.proto.ListPointSpentSheduleRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPointSpentSheduleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointSpentSheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointSpentSheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointSpentSheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointSpentSheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PointShedule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointSpentSheduleResponse}
 */
proto.proto.ListPointSpentSheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointSpentSheduleResponse;
  return proto.proto.ListPointSpentSheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointSpentSheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointSpentSheduleResponse}
 */
proto.proto.ListPointSpentSheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PointShedule;
      reader.readMessage(value,proto.proto.PointShedule.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointSpentSheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointSpentSheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointSpentSheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointSpentSheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PointShedule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointShedule items = 1;
 * @return {!Array<!proto.proto.PointShedule>}
 */
proto.proto.ListPointSpentSheduleResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PointShedule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PointShedule, 1));
};


/**
 * @param {!Array<!proto.proto.PointShedule>} value
 * @return {!proto.proto.ListPointSpentSheduleResponse} returns this
*/
proto.proto.ListPointSpentSheduleResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PointShedule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PointShedule}
 */
proto.proto.ListPointSpentSheduleResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PointShedule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointSpentSheduleResponse} returns this
 */
proto.proto.ListPointSpentSheduleResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointBalanceSheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointBalanceSheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointBalanceSheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointBalanceSheduleRequest}
 */
proto.proto.ListPointBalanceSheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointBalanceSheduleRequest;
  return proto.proto.ListPointBalanceSheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointBalanceSheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointBalanceSheduleRequest}
 */
proto.proto.ListPointBalanceSheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointBalanceSheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointBalanceSheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointBalanceSheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointBalanceSheduleRequest} returns this
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_begin = 2;
 * @return {string}
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointBalanceSheduleRequest} returns this
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date_end = 3;
 * @return {string}
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointBalanceSheduleRequest} returns this
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mode = 4;
 * @return {string}
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.getMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListPointBalanceSheduleRequest} returns this
 */
proto.proto.ListPointBalanceSheduleRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPointBalanceSheduleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointBalanceSheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointBalanceSheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointBalanceSheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointBalanceSheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PointShedule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointBalanceSheduleResponse}
 */
proto.proto.ListPointBalanceSheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointBalanceSheduleResponse;
  return proto.proto.ListPointBalanceSheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointBalanceSheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointBalanceSheduleResponse}
 */
proto.proto.ListPointBalanceSheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PointShedule;
      reader.readMessage(value,proto.proto.PointShedule.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointBalanceSheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointBalanceSheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointBalanceSheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointBalanceSheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PointShedule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointShedule items = 1;
 * @return {!Array<!proto.proto.PointShedule>}
 */
proto.proto.ListPointBalanceSheduleResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PointShedule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PointShedule, 1));
};


/**
 * @param {!Array<!proto.proto.PointShedule>} value
 * @return {!proto.proto.ListPointBalanceSheduleResponse} returns this
*/
proto.proto.ListPointBalanceSheduleResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PointShedule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PointShedule}
 */
proto.proto.ListPointBalanceSheduleResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PointShedule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointBalanceSheduleResponse} returns this
 */
proto.proto.ListPointBalanceSheduleResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CountPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CountPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CountPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pointTypeCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pointTypeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pointCurrent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pointEarned: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pointSpent: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CountPoint}
 */
proto.proto.CountPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CountPoint;
  return proto.proto.CountPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CountPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CountPoint}
 */
proto.proto.CountPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointTypeCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointTypeName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointCurrent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointEarned(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointSpent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CountPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CountPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CountPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointTypeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPointTypeCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPointTypeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPointCurrent();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPointEarned();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPointSpent();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 point_type_id = 1;
 * @return {number}
 */
proto.proto.CountPoint.prototype.getPointTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPoint} returns this
 */
proto.proto.CountPoint.prototype.setPointTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string point_type_code = 2;
 * @return {string}
 */
proto.proto.CountPoint.prototype.getPointTypeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPoint} returns this
 */
proto.proto.CountPoint.prototype.setPointTypeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string point_type_name = 3;
 * @return {string}
 */
proto.proto.CountPoint.prototype.getPointTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPoint} returns this
 */
proto.proto.CountPoint.prototype.setPointTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 point_current = 4;
 * @return {number}
 */
proto.proto.CountPoint.prototype.getPointCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPoint} returns this
 */
proto.proto.CountPoint.prototype.setPointCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 point_earned = 5;
 * @return {number}
 */
proto.proto.CountPoint.prototype.getPointEarned = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPoint} returns this
 */
proto.proto.CountPoint.prototype.setPointEarned = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 point_spent = 6;
 * @return {number}
 */
proto.proto.CountPoint.prototype.getPointSpent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPoint} returns this
 */
proto.proto.CountPoint.prototype.setPointSpent = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CountPointRequest.repeatedFields_ = [2,3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CountPointRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CountPointRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CountPointRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pointTypeIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CountPointRequest}
 */
proto.proto.CountPointRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CountPointRequest;
  return proto.proto.CountPointRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CountPointRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CountPointRequest}
 */
proto.proto.CountPointRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPointTypeIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CountPointRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CountPointRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CountPointRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPointTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.CountPointRequest.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 project_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.CountPointRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * repeated int64 event_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.CountPointRequest.prototype.getEventIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};


/**
 * optional string date_begin = 4;
 * @return {string}
 */
proto.proto.CountPointRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date_end = 5;
 * @return {string}
 */
proto.proto.CountPointRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated int64 point_type_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.CountPointRequest.prototype.getPointTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.setPointTypeIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.addPointTypeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointRequest} returns this
 */
proto.proto.CountPointRequest.prototype.clearPointTypeIdsList = function() {
  return this.setPointTypeIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CountPointResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CountPointResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CountPointResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CountPointResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.CountPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CountPointResponse}
 */
proto.proto.CountPointResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CountPointResponse;
  return proto.proto.CountPointResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CountPointResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CountPointResponse}
 */
proto.proto.CountPointResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.CountPoint;
      reader.readMessage(value,proto.proto.CountPoint.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CountPointResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CountPointResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CountPointResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.CountPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CountPoint items = 1;
 * @return {!Array<!proto.proto.CountPoint>}
 */
proto.proto.CountPointResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.CountPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.CountPoint, 1));
};


/**
 * @param {!Array<!proto.proto.CountPoint>} value
 * @return {!proto.proto.CountPointResponse} returns this
*/
proto.proto.CountPointResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.CountPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPoint}
 */
proto.proto.CountPointResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.CountPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointResponse} returns this
 */
proto.proto.CountPointResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CountPointSegmentRequest.repeatedFields_ = [1,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CountPointSegmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CountPointSegmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CountPointSegmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointSegmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    fromMetricAlias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromMetricValueIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    toMetricAlias: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toMetricValueIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    dateFrom: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dateTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    value: jspb.Message.getFieldWithDefault(msg, 8, 0),
    percent: jspb.Message.getFieldWithDefault(msg, 9, 0),
    isAdditionalParams: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CountPointSegmentRequest}
 */
proto.proto.CountPointSegmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CountPointSegmentRequest;
  return proto.proto.CountPointSegmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CountPointSegmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CountPointSegmentRequest}
 */
proto.proto.CountPointSegmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromMetricAlias(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFromMetricValueIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToMetricAlias(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addToMetricValueIds(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFrom(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateTo(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPercent(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdditionalParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CountPointSegmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CountPointSegmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CountPointSegmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointSegmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getFromMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromMetricValueIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getToMetricAlias();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToMetricValueIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getDateFrom();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDateTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getIsAdditionalParams();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * repeated uint64 project_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.CountPointSegmentRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional string from_metric_alias = 2;
 * @return {string}
 */
proto.proto.CountPointSegmentRequest.prototype.getFromMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setFromMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated uint64 from_metric_value_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.CountPointSegmentRequest.prototype.getFromMetricValueIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setFromMetricValueIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.addFromMetricValueIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.clearFromMetricValueIdsList = function() {
  return this.setFromMetricValueIdsList([]);
};


/**
 * optional string to_metric_alias = 4;
 * @return {string}
 */
proto.proto.CountPointSegmentRequest.prototype.getToMetricAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setToMetricAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated uint64 to_metric_value_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.CountPointSegmentRequest.prototype.getToMetricValueIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setToMetricValueIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.addToMetricValueIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.clearToMetricValueIdsList = function() {
  return this.setToMetricValueIdsList([]);
};


/**
 * optional string date_from = 6;
 * @return {string}
 */
proto.proto.CountPointSegmentRequest.prototype.getDateFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setDateFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string date_to = 7;
 * @return {string}
 */
proto.proto.CountPointSegmentRequest.prototype.getDateTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setDateTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 value = 8;
 * @return {number}
 */
proto.proto.CountPointSegmentRequest.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 percent = 9;
 * @return {number}
 */
proto.proto.CountPointSegmentRequest.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setPercent = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool is_additional_params = 10;
 * @return {boolean}
 */
proto.proto.CountPointSegmentRequest.prototype.getIsAdditionalParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.CountPointSegmentRequest} returns this
 */
proto.proto.CountPointSegmentRequest.prototype.setIsAdditionalParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CountPointSegmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CountPointSegmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CountPointSegmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointSegmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cntEarnedOnly: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cntSpentAll: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cntSpentPart: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cntNoTransactions: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CountPointSegmentResponse}
 */
proto.proto.CountPointSegmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CountPointSegmentResponse;
  return proto.proto.CountPointSegmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CountPointSegmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CountPointSegmentResponse}
 */
proto.proto.CountPointSegmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCntEarnedOnly(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCntSpentAll(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCntSpentPart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCntNoTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CountPointSegmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CountPointSegmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CountPointSegmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointSegmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCntEarnedOnly();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCntSpentAll();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCntSpentPart();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCntNoTransactions();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 cnt_earned_only = 1;
 * @return {number}
 */
proto.proto.CountPointSegmentResponse.prototype.getCntEarnedOnly = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPointSegmentResponse} returns this
 */
proto.proto.CountPointSegmentResponse.prototype.setCntEarnedOnly = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 cnt_spent_all = 2;
 * @return {number}
 */
proto.proto.CountPointSegmentResponse.prototype.getCntSpentAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPointSegmentResponse} returns this
 */
proto.proto.CountPointSegmentResponse.prototype.setCntSpentAll = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 cnt_spent_part = 3;
 * @return {number}
 */
proto.proto.CountPointSegmentResponse.prototype.getCntSpentPart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPointSegmentResponse} returns this
 */
proto.proto.CountPointSegmentResponse.prototype.setCntSpentPart = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt_no_transactions = 4;
 * @return {number}
 */
proto.proto.CountPointSegmentResponse.prototype.getCntNoTransactions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CountPointSegmentResponse} returns this
 */
proto.proto.CountPointSegmentResponse.prototype.setCntNoTransactions = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ProjectCount.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ProjectCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ProjectCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProjectCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pointCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ProjectCount}
 */
proto.proto.ProjectCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ProjectCount;
  return proto.proto.ProjectCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ProjectCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ProjectCount}
 */
proto.proto.ProjectCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ProjectCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ProjectCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ProjectCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ProjectCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPointCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 project_id = 1;
 * @return {number}
 */
proto.proto.ProjectCount.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProjectCount} returns this
 */
proto.proto.ProjectCount.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string project_name = 2;
 * @return {string}
 */
proto.proto.ProjectCount.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ProjectCount} returns this
 */
proto.proto.ProjectCount.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 point_count = 3;
 * @return {number}
 */
proto.proto.ProjectCount.prototype.getPointCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ProjectCount} returns this
 */
proto.proto.ProjectCount.prototype.setPointCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CountPointEarnedByProjectRequest.repeatedFields_ = [2,3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CountPointEarnedByProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CountPointEarnedByProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointEarnedByProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pointTypeIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    sort: (f = msg.getSort()) && filter_pb.Sort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && filter_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CountPointEarnedByProjectRequest}
 */
proto.proto.CountPointEarnedByProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CountPointEarnedByProjectRequest;
  return proto.proto.CountPointEarnedByProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CountPointEarnedByProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CountPointEarnedByProjectRequest}
 */
proto.proto.CountPointEarnedByProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPointTypeIds(values[i]);
      }
      break;
    case 7:
      var value = new filter_pb.Sort;
      reader.readMessage(value,filter_pb.Sort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 8:
      var value = new filter_pb.Pagination;
      reader.readMessage(value,filter_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CountPointEarnedByProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CountPointEarnedByProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointEarnedByProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPointTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      filter_pb.Sort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      filter_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 project_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * repeated int64 event_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.getEventIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};


/**
 * optional string date_begin = 4;
 * @return {string}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date_end = 5;
 * @return {string}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated int64 point_type_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.getPointTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.setPointTypeIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.addPointTypeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.clearPointTypeIdsList = function() {
  return this.setPointTypeIdsList([]);
};


/**
 * optional Sort sort = 7;
 * @return {?proto.proto.Sort}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.Sort} */ (
    jspb.Message.getWrapperField(this, filter_pb.Sort, 7));
};


/**
 * @param {?proto.proto.Sort|undefined} value
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
*/
proto.proto.CountPointEarnedByProjectRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Pagination pagination = 8;
 * @return {?proto.proto.Pagination}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, filter_pb.Pagination, 8));
};


/**
 * @param {?proto.proto.Pagination|undefined} value
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
*/
proto.proto.CountPointEarnedByProjectRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CountPointEarnedByProjectRequest} returns this
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CountPointEarnedByProjectRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CountPointEarnedByProjectResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CountPointEarnedByProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CountPointEarnedByProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CountPointEarnedByProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointEarnedByProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.ProjectCount.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.CountPointEarnedByProjectRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CountPointEarnedByProjectResponse}
 */
proto.proto.CountPointEarnedByProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CountPointEarnedByProjectResponse;
  return proto.proto.CountPointEarnedByProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CountPointEarnedByProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CountPointEarnedByProjectResponse}
 */
proto.proto.CountPointEarnedByProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ProjectCount;
      reader.readMessage(value,proto.proto.ProjectCount.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.CountPointEarnedByProjectRequest;
      reader.readMessage(value,proto.proto.CountPointEarnedByProjectRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CountPointEarnedByProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CountPointEarnedByProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CountPointEarnedByProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CountPointEarnedByProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.ProjectCount.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.CountPointEarnedByProjectRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProjectCount items = 1;
 * @return {!Array<!proto.proto.ProjectCount>}
 */
proto.proto.CountPointEarnedByProjectResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.ProjectCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ProjectCount, 1));
};


/**
 * @param {!Array<!proto.proto.ProjectCount>} value
 * @return {!proto.proto.CountPointEarnedByProjectResponse} returns this
*/
proto.proto.CountPointEarnedByProjectResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ProjectCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ProjectCount}
 */
proto.proto.CountPointEarnedByProjectResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ProjectCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CountPointEarnedByProjectResponse} returns this
 */
proto.proto.CountPointEarnedByProjectResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional CountPointEarnedByProjectRequest params = 2;
 * @return {?proto.proto.CountPointEarnedByProjectRequest}
 */
proto.proto.CountPointEarnedByProjectResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.CountPointEarnedByProjectRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.CountPointEarnedByProjectRequest, 2));
};


/**
 * @param {?proto.proto.CountPointEarnedByProjectRequest|undefined} value
 * @return {!proto.proto.CountPointEarnedByProjectResponse} returns this
*/
proto.proto.CountPointEarnedByProjectResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CountPointEarnedByProjectResponse} returns this
 */
proto.proto.CountPointEarnedByProjectResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CountPointEarnedByProjectResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ExportListPointResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ExportListPointResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ExportListPointResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExportListPointResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ExportListPointResponse}
 */
proto.proto.ExportListPointResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ExportListPointResponse;
  return proto.proto.ExportListPointResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ExportListPointResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ExportListPointResponse}
 */
proto.proto.ExportListPointResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ExportListPointResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ExportListPointResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ExportListPointResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExportListPointResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.proto.ExportListPointResponse.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.proto.ExportListPointResponse.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.proto.ExportListPointResponse.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.proto.ExportListPointResponse} returns this
 */
proto.proto.ExportListPointResponse.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PointType.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PointType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PointType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PointType.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pointTypeCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pointTypeName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PointType}
 */
proto.proto.PointType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PointType;
  return proto.proto.PointType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PointType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PointType}
 */
proto.proto.PointType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPointTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointTypeCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointTypeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PointType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PointType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PointType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PointType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointTypeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPointTypeCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPointTypeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 point_type_id = 1;
 * @return {number}
 */
proto.proto.PointType.prototype.getPointTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PointType} returns this
 */
proto.proto.PointType.prototype.setPointTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string point_type_code = 2;
 * @return {string}
 */
proto.proto.PointType.prototype.getPointTypeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointType} returns this
 */
proto.proto.PointType.prototype.setPointTypeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string point_type_name = 3;
 * @return {string}
 */
proto.proto.PointType.prototype.getPointTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PointType} returns this
 */
proto.proto.PointType.prototype.setPointTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointTypesRequest}
 */
proto.proto.ListPointTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointTypesRequest;
  return proto.proto.ListPointTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointTypesRequest}
 */
proto.proto.ListPointTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPointTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPointTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPointTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPointTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PointType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPointTypesResponse}
 */
proto.proto.ListPointTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPointTypesResponse;
  return proto.proto.ListPointTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPointTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPointTypesResponse}
 */
proto.proto.ListPointTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PointType;
      reader.readMessage(value,proto.proto.PointType.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPointTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPointTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPointTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPointTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PointType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PointType items = 1;
 * @return {!Array<!proto.proto.PointType>}
 */
proto.proto.ListPointTypesResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PointType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PointType, 1));
};


/**
 * @param {!Array<!proto.proto.PointType>} value
 * @return {!proto.proto.ListPointTypesResponse} returns this
*/
proto.proto.ListPointTypesResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PointType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PointType}
 */
proto.proto.ListPointTypesResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PointType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPointTypesResponse} returns this
 */
proto.proto.ListPointTypesResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
