import { StyleValue } from "vue";
import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/recommend-system/task-builder",
    name: "ManualTaskBuilder",
    component: () => import("../view/ManualBuilderView.vue"),
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Рекомендательная система", to: { name: "RecSystem" } },
        { text: 'Настройка блоков', to: { name: 'RecSystemCMS' } },
        { text: 'Блок «Доступные активности»', to: { name: 'RecSystemCMSAvailableActivities' } },
        { text: 'Настройка ручных активностей' }
      ],
      style: {
        backgroundColor: 'white',
        transition: 'all .3s ease'
      } satisfies StyleValue
    },
  },
];

export default {
  routes,
};
