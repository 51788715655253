/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: accounts_src.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as accounts_src_pb from './accounts_src_pb';


export class AccountSrcServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListAccountSrc = new grpcWeb.MethodDescriptor(
    '/proto.AccountSrcService/ListAccountSrc',
    grpcWeb.MethodType.UNARY,
    accounts_src_pb.ListAccountSrcRequest,
    accounts_src_pb.ListAccountSrcResponse,
    (request: accounts_src_pb.ListAccountSrcRequest) => {
      return request.serializeBinary();
    },
    accounts_src_pb.ListAccountSrcResponse.deserializeBinary
  );

  listAccountSrc(
    request: accounts_src_pb.ListAccountSrcRequest,
    metadata: grpcWeb.Metadata | null): Promise<accounts_src_pb.ListAccountSrcResponse>;

  listAccountSrc(
    request: accounts_src_pb.ListAccountSrcRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: accounts_src_pb.ListAccountSrcResponse) => void): grpcWeb.ClientReadableStream<accounts_src_pb.ListAccountSrcResponse>;

  listAccountSrc(
    request: accounts_src_pb.ListAccountSrcRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: accounts_src_pb.ListAccountSrcResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AccountSrcService/ListAccountSrc',
        request,
        metadata || {},
        this.methodDescriptorListAccountSrc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AccountSrcService/ListAccountSrc',
    request,
    metadata || {},
    this.methodDescriptorListAccountSrc);
  }

  methodDescriptorItemAccountSrc = new grpcWeb.MethodDescriptor(
    '/proto.AccountSrcService/ItemAccountSrc',
    grpcWeb.MethodType.UNARY,
    accounts_src_pb.ItemAccountSrcRequest,
    accounts_src_pb.ItemAccountSrcResponse,
    (request: accounts_src_pb.ItemAccountSrcRequest) => {
      return request.serializeBinary();
    },
    accounts_src_pb.ItemAccountSrcResponse.deserializeBinary
  );

  itemAccountSrc(
    request: accounts_src_pb.ItemAccountSrcRequest,
    metadata: grpcWeb.Metadata | null): Promise<accounts_src_pb.ItemAccountSrcResponse>;

  itemAccountSrc(
    request: accounts_src_pb.ItemAccountSrcRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: accounts_src_pb.ItemAccountSrcResponse) => void): grpcWeb.ClientReadableStream<accounts_src_pb.ItemAccountSrcResponse>;

  itemAccountSrc(
    request: accounts_src_pb.ItemAccountSrcRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: accounts_src_pb.ItemAccountSrcResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AccountSrcService/ItemAccountSrc',
        request,
        metadata || {},
        this.methodDescriptorItemAccountSrc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AccountSrcService/ItemAccountSrc',
    request,
    metadata || {},
    this.methodDescriptorItemAccountSrc);
  }

}

