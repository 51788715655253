import script from "./ProjectFolder.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProjectFolder.vue?vue&type=script&setup=true&lang=ts"

import "./ProjectFolder.vue?vue&type=style&index=0&id=3a6720a1&lang=scss"

const __exports__ = script;

export default __exports__
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTooltip});
