// source: point.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var interval_pb = require('./interval_pb.js');
goog.object.extend(proto, interval_pb);
goog.exportSymbol('proto.proto.DashboardEarnedByProject', null, global);
goog.exportSymbol('proto.proto.DashboardEarnedChart', null, global);
goog.exportSymbol('proto.proto.DashboardEarnedChartPoint', null, global);
goog.exportSymbol('proto.proto.DashboardEarnedPoint', null, global);
goog.exportSymbol('proto.proto.DashboardEarnedPointGroupBy', null, global);
goog.exportSymbol('proto.proto.DashboardPointActiv', null, global);
goog.exportSymbol('proto.proto.DashboardPointFilter', null, global);
goog.exportSymbol('proto.proto.DashboardPointFilterItem', null, global);
goog.exportSymbol('proto.proto.DashboardPointPagination', null, global);
goog.exportSymbol('proto.proto.DashboardPointSort', null, global);
goog.exportSymbol('proto.proto.ExportListDashboardPointEarnedPointsResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardPointEarnedByProjectRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardPointEarnedByProjectResponse', null, global);
goog.exportSymbol('proto.proto.GetDashboardPointMinMaxDateRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardPointMinMaxDateResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardPointActivListRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardPointActivListResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardPointEarnedChartRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardPointEarnedChartResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardPointEarnedGroupByRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardPointEarnedGroupByResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardPointEarnedPointsRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardPointEarnedPointsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardEarnedPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardEarnedPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardEarnedPoint.displayName = 'proto.proto.DashboardEarnedPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardPointActiv = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardPointActiv, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardPointActiv.displayName = 'proto.proto.DashboardPointActiv';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardPointFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardPointFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardPointFilterItem.displayName = 'proto.proto.DashboardPointFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardPointFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardPointFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardPointFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardPointFilter.displayName = 'proto.proto.DashboardPointFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardPointSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardPointSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardPointSort.displayName = 'proto.proto.DashboardPointSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardPointPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardPointPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardPointPagination.displayName = 'proto.proto.DashboardPointPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardPointEarnedPointsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardPointEarnedPointsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardPointEarnedPointsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardPointEarnedPointsRequest.displayName = 'proto.proto.ListDashboardPointEarnedPointsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardPointEarnedPointsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardPointEarnedPointsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardPointEarnedPointsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardPointEarnedPointsResponse.displayName = 'proto.proto.ListDashboardPointEarnedPointsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardPointActivListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardPointActivListRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardPointActivListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardPointActivListRequest.displayName = 'proto.proto.ListDashboardPointActivListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardPointActivListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardPointActivListResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardPointActivListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardPointActivListResponse.displayName = 'proto.proto.ListDashboardPointActivListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardPointEarnedChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardPointEarnedChartRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardPointEarnedChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardPointEarnedChartRequest.displayName = 'proto.proto.ListDashboardPointEarnedChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardEarnedChart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardEarnedChart.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardEarnedChart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardEarnedChart.displayName = 'proto.proto.DashboardEarnedChart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardEarnedChartPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardEarnedChartPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardEarnedChartPoint.displayName = 'proto.proto.DashboardEarnedChartPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardPointEarnedChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardPointEarnedChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardPointEarnedChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardPointEarnedChartResponse.displayName = 'proto.proto.ListDashboardPointEarnedChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardPointEarnedByProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardPointEarnedByProjectRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardPointEarnedByProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardPointEarnedByProjectRequest.displayName = 'proto.proto.GetDashboardPointEarnedByProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardEarnedByProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardEarnedByProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardEarnedByProject.displayName = 'proto.proto.DashboardEarnedByProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardPointEarnedByProjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardPointEarnedByProjectResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardPointEarnedByProjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardPointEarnedByProjectResponse.displayName = 'proto.proto.GetDashboardPointEarnedByProjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardPointMinMaxDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardPointMinMaxDateRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardPointMinMaxDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardPointMinMaxDateRequest.displayName = 'proto.proto.GetDashboardPointMinMaxDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardPointMinMaxDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardPointMinMaxDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardPointMinMaxDateResponse.displayName = 'proto.proto.GetDashboardPointMinMaxDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardEarnedPointGroupBy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardEarnedPointGroupBy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardEarnedPointGroupBy.displayName = 'proto.proto.DashboardEarnedPointGroupBy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardPointEarnedGroupByRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardPointEarnedGroupByRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardPointEarnedGroupByRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardPointEarnedGroupByRequest.displayName = 'proto.proto.ListDashboardPointEarnedGroupByRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardPointEarnedGroupByResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardPointEarnedGroupByResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardPointEarnedGroupByResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardPointEarnedGroupByResponse.displayName = 'proto.proto.ListDashboardPointEarnedGroupByResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ExportListDashboardPointEarnedPointsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ExportListDashboardPointEarnedPointsResponse.displayName = 'proto.proto.ExportListDashboardPointEarnedPointsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardEarnedPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardEarnedPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardEarnedPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    projectName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    activText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    transactionDt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    value: jspb.Message.getFieldWithDefault(msg, 7, 0),
    description: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardEarnedPoint}
 */
proto.proto.DashboardEarnedPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardEarnedPoint;
  return proto.proto.DashboardEarnedPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardEarnedPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardEarnedPoint}
 */
proto.proto.DashboardEarnedPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionDt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardEarnedPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardEarnedPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardEarnedPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivId();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getActivText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTransactionDt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.DashboardEarnedPoint.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPoint} returns this
 */
proto.proto.DashboardEarnedPoint.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 project_id = 2;
 * @return {number}
 */
proto.proto.DashboardEarnedPoint.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardEarnedPoint} returns this
 */
proto.proto.DashboardEarnedPoint.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string project_name = 3;
 * @return {string}
 */
proto.proto.DashboardEarnedPoint.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPoint} returns this
 */
proto.proto.DashboardEarnedPoint.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 activ_id = 4;
 * @return {number}
 */
proto.proto.DashboardEarnedPoint.prototype.getActivId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardEarnedPoint} returns this
 */
proto.proto.DashboardEarnedPoint.prototype.setActivId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string activ_text = 5;
 * @return {string}
 */
proto.proto.DashboardEarnedPoint.prototype.getActivText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPoint} returns this
 */
proto.proto.DashboardEarnedPoint.prototype.setActivText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string transaction_dt = 6;
 * @return {string}
 */
proto.proto.DashboardEarnedPoint.prototype.getTransactionDt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPoint} returns this
 */
proto.proto.DashboardEarnedPoint.prototype.setTransactionDt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 value = 7;
 * @return {number}
 */
proto.proto.DashboardEarnedPoint.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardEarnedPoint} returns this
 */
proto.proto.DashboardEarnedPoint.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.proto.DashboardEarnedPoint.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPoint} returns this
 */
proto.proto.DashboardEarnedPoint.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardPointActiv.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardPointActiv.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardPointActiv} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointActiv.toObject = function(includeInstance, msg) {
  var f, obj = {
    activId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardPointActiv}
 */
proto.proto.DashboardPointActiv.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardPointActiv;
  return proto.proto.DashboardPointActiv.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardPointActiv} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardPointActiv}
 */
proto.proto.DashboardPointActiv.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardPointActiv.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardPointActiv.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardPointActiv} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointActiv.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getActivText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional uint64 activ_id = 1;
 * @return {number}
 */
proto.proto.DashboardPointActiv.prototype.getActivId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardPointActiv} returns this
 */
proto.proto.DashboardPointActiv.prototype.setActivId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activ_text = 2;
 * @return {string}
 */
proto.proto.DashboardPointActiv.prototype.getActivText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardPointActiv} returns this
 */
proto.proto.DashboardPointActiv.prototype.setActivText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 project_id = 3;
 * @return {number}
 */
proto.proto.DashboardPointActiv.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardPointActiv} returns this
 */
proto.proto.DashboardPointActiv.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardPointFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardPointFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardPointFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.DashboardPointFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardPointFilterItem}
 */
proto.proto.DashboardPointFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardPointFilterItem;
  return proto.proto.DashboardPointFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardPointFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardPointFilterItem}
 */
proto.proto.DashboardPointFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.DashboardPointFilter;
      reader.readMessage(value,proto.proto.DashboardPointFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardPointFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardPointFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardPointFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.DashboardPointFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.DashboardPointFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardPointFilterItem} returns this
 */
proto.proto.DashboardPointFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.DashboardPointFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardPointFilterItem} returns this
 */
proto.proto.DashboardPointFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.DashboardPointFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardPointFilterItem} returns this
 */
proto.proto.DashboardPointFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DashboardPointFilter or = 4;
 * @return {?proto.proto.DashboardPointFilter}
 */
proto.proto.DashboardPointFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.DashboardPointFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardPointFilter, 4));
};


/**
 * @param {?proto.proto.DashboardPointFilter|undefined} value
 * @return {!proto.proto.DashboardPointFilterItem} returns this
*/
proto.proto.DashboardPointFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DashboardPointFilterItem} returns this
 */
proto.proto.DashboardPointFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DashboardPointFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardPointFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardPointFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardPointFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardPointFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardPointFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardPointFilter}
 */
proto.proto.DashboardPointFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardPointFilter;
  return proto.proto.DashboardPointFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardPointFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardPointFilter}
 */
proto.proto.DashboardPointFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardPointFilterItem;
      reader.readMessage(value,proto.proto.DashboardPointFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardPointFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardPointFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardPointFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardPointFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardPointFilterItem items = 1;
 * @return {!Array<!proto.proto.DashboardPointFilterItem>}
 */
proto.proto.DashboardPointFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardPointFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardPointFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardPointFilterItem>} value
 * @return {!proto.proto.DashboardPointFilter} returns this
*/
proto.proto.DashboardPointFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardPointFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardPointFilterItem}
 */
proto.proto.DashboardPointFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardPointFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardPointFilter} returns this
 */
proto.proto.DashboardPointFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardPointSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardPointSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardPointSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardPointSort}
 */
proto.proto.DashboardPointSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardPointSort;
  return proto.proto.DashboardPointSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardPointSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardPointSort}
 */
proto.proto.DashboardPointSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardPointSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardPointSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardPointSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.DashboardPointSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardPointSort} returns this
 */
proto.proto.DashboardPointSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.DashboardPointSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardPointSort} returns this
 */
proto.proto.DashboardPointSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardPointPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardPointPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardPointPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardPointPagination}
 */
proto.proto.DashboardPointPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardPointPagination;
  return proto.proto.DashboardPointPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardPointPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardPointPagination}
 */
proto.proto.DashboardPointPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardPointPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardPointPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardPointPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardPointPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.proto.DashboardPointPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardPointPagination} returns this
 */
proto.proto.DashboardPointPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.DashboardPointPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardPointPagination} returns this
 */
proto.proto.DashboardPointPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 pages = 3;
 * @return {number}
 */
proto.proto.DashboardPointPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardPointPagination} returns this
 */
proto.proto.DashboardPointPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 cnt = 4;
 * @return {number}
 */
proto.proto.DashboardPointPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardPointPagination} returns this
 */
proto.proto.DashboardPointPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardPointEarnedPointsRequest.repeatedFields_ = [4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardPointEarnedPointsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardPointEarnedPointsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedPointsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardPointFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardPointSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.DashboardPointPagination.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    activIdsList: jspb.Message.toObjectList(msg.getActivIdsList(),
    proto.proto.DashboardPointActiv.toObject, includeInstance),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardPointEarnedPointsRequest;
  return proto.proto.ListDashboardPointEarnedPointsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardPointEarnedPointsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardPointFilter;
      reader.readMessage(value,proto.proto.DashboardPointFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardPointSort;
      reader.readMessage(value,proto.proto.DashboardPointSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.DashboardPointPagination;
      reader.readMessage(value,proto.proto.DashboardPointPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 8:
      var value = new proto.proto.DashboardPointActiv;
      reader.readMessage(value,proto.proto.DashboardPointActiv.deserializeBinaryFromReader);
      msg.addActivIds(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardPointEarnedPointsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardPointEarnedPointsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedPointsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardPointFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardPointSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.DashboardPointPagination.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.proto.DashboardPointActiv.serializeBinaryToWriter
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional DashboardPointFilter filter = 1;
 * @return {?proto.proto.DashboardPointFilter}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardPointFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardPointFilter, 1));
};


/**
 * @param {?proto.proto.DashboardPointFilter|undefined} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
*/
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardPointSort sort = 2;
 * @return {?proto.proto.DashboardPointSort}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardPointSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardPointSort, 2));
};


/**
 * @param {?proto.proto.DashboardPointSort|undefined} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
*/
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DashboardPointPagination pagination = 3;
 * @return {?proto.proto.DashboardPointPagination}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardPointPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardPointPagination, 3));
};


/**
 * @param {?proto.proto.DashboardPointPagination|undefined} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
*/
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated uint64 activity_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated DashboardPointActiv activ_ids = 8;
 * @return {!Array<!proto.proto.DashboardPointActiv>}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getActivIdsList = function() {
  return /** @type{!Array<!proto.proto.DashboardPointActiv>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardPointActiv, 8));
};


/**
 * @param {!Array<!proto.proto.DashboardPointActiv>} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
*/
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.proto.DashboardPointActiv=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardPointActiv}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.addActivIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.proto.DashboardPointActiv, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional string date_begin = 9;
 * @return {string}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string date_end = 10;
 * @return {string}
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsRequest} returns this
 */
proto.proto.ListDashboardPointEarnedPointsRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardPointEarnedPointsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardPointEarnedPointsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardPointEarnedPointsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedPointsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardEarnedPoint.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardPointEarnedPointsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardPointEarnedPointsResponse}
 */
proto.proto.ListDashboardPointEarnedPointsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardPointEarnedPointsResponse;
  return proto.proto.ListDashboardPointEarnedPointsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardPointEarnedPointsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardPointEarnedPointsResponse}
 */
proto.proto.ListDashboardPointEarnedPointsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardEarnedPoint;
      reader.readMessage(value,proto.proto.DashboardEarnedPoint.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardPointEarnedPointsRequest;
      reader.readMessage(value,proto.proto.ListDashboardPointEarnedPointsRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardPointEarnedPointsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardPointEarnedPointsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedPointsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardEarnedPoint.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardPointEarnedPointsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardEarnedPoint items = 1;
 * @return {!Array<!proto.proto.DashboardEarnedPoint>}
 */
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardEarnedPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardEarnedPoint, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardEarnedPoint>} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsResponse} returns this
*/
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardEarnedPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardEarnedPoint}
 */
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardEarnedPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedPointsResponse} returns this
 */
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardPointEarnedPointsRequest params = 2;
 * @return {?proto.proto.ListDashboardPointEarnedPointsRequest}
 */
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardPointEarnedPointsRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardPointEarnedPointsRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardPointEarnedPointsRequest|undefined} value
 * @return {!proto.proto.ListDashboardPointEarnedPointsResponse} returns this
*/
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardPointEarnedPointsResponse} returns this
 */
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardPointEarnedPointsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardPointActivListRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardPointActivListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardPointActivListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardPointActivListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointActivListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardPointActivListRequest}
 */
proto.proto.ListDashboardPointActivListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardPointActivListRequest;
  return proto.proto.ListDashboardPointActivListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardPointActivListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardPointActivListRequest}
 */
proto.proto.ListDashboardPointActivListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardPointActivListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardPointActivListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardPointActivListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointActivListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
};


/**
 * repeated uint64 project_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointActivListRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointActivListRequest} returns this
 */
proto.proto.ListDashboardPointActivListRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointActivListRequest} returns this
 */
proto.proto.ListDashboardPointActivListRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointActivListRequest} returns this
 */
proto.proto.ListDashboardPointActivListRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardPointActivListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardPointActivListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardPointActivListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardPointActivListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointActivListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardPointActiv.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardPointActivListResponse}
 */
proto.proto.ListDashboardPointActivListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardPointActivListResponse;
  return proto.proto.ListDashboardPointActivListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardPointActivListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardPointActivListResponse}
 */
proto.proto.ListDashboardPointActivListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardPointActiv;
      reader.readMessage(value,proto.proto.DashboardPointActiv.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardPointActivListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardPointActivListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardPointActivListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointActivListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardPointActiv.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardPointActiv items = 1;
 * @return {!Array<!proto.proto.DashboardPointActiv>}
 */
proto.proto.ListDashboardPointActivListResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardPointActiv>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardPointActiv, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardPointActiv>} value
 * @return {!proto.proto.ListDashboardPointActivListResponse} returns this
*/
proto.proto.ListDashboardPointActivListResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardPointActiv=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardPointActiv}
 */
proto.proto.ListDashboardPointActivListResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardPointActiv, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointActivListResponse} returns this
 */
proto.proto.ListDashboardPointActivListResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardPointEarnedChartRequest.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardPointEarnedChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardPointEarnedChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    activIdsList: jspb.Message.toObjectList(msg.getActivIdsList(),
    proto.proto.DashboardPointActiv.toObject, includeInstance),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 7, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest}
 */
proto.proto.ListDashboardPointEarnedChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardPointEarnedChartRequest;
  return proto.proto.ListDashboardPointEarnedChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardPointEarnedChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest}
 */
proto.proto.ListDashboardPointEarnedChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 5:
      var value = new proto.proto.DashboardPointActiv;
      reader.readMessage(value,proto.proto.DashboardPointActiv.deserializeBinaryFromReader);
      msg.addActivIds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 8:
      var value = /** @type {!proto.proto.ChartInterval} */ (reader.readEnum());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardPointEarnedChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardPointEarnedChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.proto.DashboardPointActiv.serializeBinaryToWriter
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * repeated uint64 activity_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated DashboardPointActiv activ_ids = 5;
 * @return {!Array<!proto.proto.DashboardPointActiv>}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.getActivIdsList = function() {
  return /** @type{!Array<!proto.proto.DashboardPointActiv>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardPointActiv, 5));
};


/**
 * @param {!Array<!proto.proto.DashboardPointActiv>} value
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
*/
proto.proto.ListDashboardPointEarnedChartRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.proto.DashboardPointActiv=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardPointActiv}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.addActivIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.proto.DashboardPointActiv, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional string date_begin = 6;
 * @return {string}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string date_end = 7;
 * @return {string}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional ChartInterval interval = 8;
 * @return {!proto.proto.ChartInterval}
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.getInterval = function() {
  return /** @type {!proto.proto.ChartInterval} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.proto.ChartInterval} value
 * @return {!proto.proto.ListDashboardPointEarnedChartRequest} returns this
 */
proto.proto.ListDashboardPointEarnedChartRequest.prototype.setInterval = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardEarnedChart.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardEarnedChart.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardEarnedChart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardEarnedChart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedChart.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.proto.DashboardEarnedChartPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardEarnedChart}
 */
proto.proto.DashboardEarnedChart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardEarnedChart;
  return proto.proto.DashboardEarnedChart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardEarnedChart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardEarnedChart}
 */
proto.proto.DashboardEarnedChart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    case 2:
      var value = new proto.proto.DashboardEarnedChartPoint;
      reader.readMessage(value,proto.proto.DashboardEarnedChartPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardEarnedChart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardEarnedChart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardEarnedChart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedChart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.DashboardEarnedChartPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project = 1;
 * @return {string}
 */
proto.proto.DashboardEarnedChart.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedChart} returns this
 */
proto.proto.DashboardEarnedChart.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DashboardEarnedChartPoint points = 2;
 * @return {!Array<!proto.proto.DashboardEarnedChartPoint>}
 */
proto.proto.DashboardEarnedChart.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.proto.DashboardEarnedChartPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardEarnedChartPoint, 2));
};


/**
 * @param {!Array<!proto.proto.DashboardEarnedChartPoint>} value
 * @return {!proto.proto.DashboardEarnedChart} returns this
*/
proto.proto.DashboardEarnedChart.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.DashboardEarnedChartPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardEarnedChartPoint}
 */
proto.proto.DashboardEarnedChart.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.DashboardEarnedChartPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardEarnedChart} returns this
 */
proto.proto.DashboardEarnedChart.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardEarnedChartPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardEarnedChartPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardEarnedChartPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedChartPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardEarnedChartPoint}
 */
proto.proto.DashboardEarnedChartPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardEarnedChartPoint;
  return proto.proto.DashboardEarnedChartPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardEarnedChartPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardEarnedChartPoint}
 */
proto.proto.DashboardEarnedChartPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardEarnedChartPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardEarnedChartPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardEarnedChartPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedChartPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.proto.DashboardEarnedChartPoint.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedChartPoint} returns this
 */
proto.proto.DashboardEarnedChartPoint.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.proto.DashboardEarnedChartPoint.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardEarnedChartPoint} returns this
 */
proto.proto.DashboardEarnedChartPoint.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardPointEarnedChartResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardPointEarnedChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardPointEarnedChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardPointEarnedChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardEarnedChart.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardPointEarnedChartResponse}
 */
proto.proto.ListDashboardPointEarnedChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardPointEarnedChartResponse;
  return proto.proto.ListDashboardPointEarnedChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardPointEarnedChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardPointEarnedChartResponse}
 */
proto.proto.ListDashboardPointEarnedChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardEarnedChart;
      reader.readMessage(value,proto.proto.DashboardEarnedChart.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardPointEarnedChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardPointEarnedChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardPointEarnedChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardEarnedChart.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardEarnedChart items = 1;
 * @return {!Array<!proto.proto.DashboardEarnedChart>}
 */
proto.proto.ListDashboardPointEarnedChartResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardEarnedChart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardEarnedChart, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardEarnedChart>} value
 * @return {!proto.proto.ListDashboardPointEarnedChartResponse} returns this
*/
proto.proto.ListDashboardPointEarnedChartResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardEarnedChart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardEarnedChart}
 */
proto.proto.ListDashboardPointEarnedChartResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardEarnedChart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedChartResponse} returns this
 */
proto.proto.ListDashboardPointEarnedChartResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardPointEarnedByProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardPointEarnedByProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    activIdsList: jspb.Message.toObjectList(msg.getActivIdsList(),
    proto.proto.DashboardPointActiv.toObject, includeInstance),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardPointEarnedByProjectRequest;
  return proto.proto.GetDashboardPointEarnedByProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardPointEarnedByProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 5:
      var value = new proto.proto.DashboardPointActiv;
      reader.readMessage(value,proto.proto.DashboardPointActiv.deserializeBinaryFromReader);
      msg.addActivIds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardPointEarnedByProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardPointEarnedByProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.proto.DashboardPointActiv.serializeBinaryToWriter
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * repeated uint64 activity_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated DashboardPointActiv activ_ids = 5;
 * @return {!Array<!proto.proto.DashboardPointActiv>}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.getActivIdsList = function() {
  return /** @type{!Array<!proto.proto.DashboardPointActiv>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardPointActiv, 5));
};


/**
 * @param {!Array<!proto.proto.DashboardPointActiv>} value
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
*/
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.proto.DashboardPointActiv=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardPointActiv}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.addActivIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.proto.DashboardPointActiv, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional string date_begin = 6;
 * @return {string}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string date_end = 7;
 * @return {string}
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardPointEarnedByProjectRequest} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardEarnedByProject.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardEarnedByProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardEarnedByProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedByProject.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardEarnedByProject}
 */
proto.proto.DashboardEarnedByProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardEarnedByProject;
  return proto.proto.DashboardEarnedByProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardEarnedByProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardEarnedByProject}
 */
proto.proto.DashboardEarnedByProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProject(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardEarnedByProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardEarnedByProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardEarnedByProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedByProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string project = 1;
 * @return {string}
 */
proto.proto.DashboardEarnedByProject.prototype.getProject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedByProject} returns this
 */
proto.proto.DashboardEarnedByProject.prototype.setProject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.proto.DashboardEarnedByProject.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardEarnedByProject} returns this
 */
proto.proto.DashboardEarnedByProject.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardPointEarnedByProjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardPointEarnedByProjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardEarnedByProject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectResponse}
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardPointEarnedByProjectResponse;
  return proto.proto.GetDashboardPointEarnedByProjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardPointEarnedByProjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectResponse}
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardEarnedByProject;
      reader.readMessage(value,proto.proto.DashboardEarnedByProject.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardPointEarnedByProjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardPointEarnedByProjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardEarnedByProject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardEarnedByProject items = 1;
 * @return {!Array<!proto.proto.DashboardEarnedByProject>}
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardEarnedByProject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardEarnedByProject, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardEarnedByProject>} value
 * @return {!proto.proto.GetDashboardPointEarnedByProjectResponse} returns this
*/
proto.proto.GetDashboardPointEarnedByProjectResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardEarnedByProject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardEarnedByProject}
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardEarnedByProject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardPointEarnedByProjectResponse} returns this
 */
proto.proto.GetDashboardPointEarnedByProjectResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardPointMinMaxDateRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardPointMinMaxDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardPointMinMaxDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardPointMinMaxDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardPointMinMaxDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activIdsList: jspb.Message.toObjectList(msg.getActivIdsList(),
    proto.proto.DashboardPointActiv.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardPointMinMaxDateRequest}
 */
proto.proto.GetDashboardPointMinMaxDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardPointMinMaxDateRequest;
  return proto.proto.GetDashboardPointMinMaxDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardPointMinMaxDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardPointMinMaxDateRequest}
 */
proto.proto.GetDashboardPointMinMaxDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardPointActiv;
      reader.readMessage(value,proto.proto.DashboardPointActiv.deserializeBinaryFromReader);
      msg.addActivIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardPointMinMaxDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardPointMinMaxDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardPointMinMaxDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardPointMinMaxDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardPointActiv.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardPointActiv activ_ids = 1;
 * @return {!Array<!proto.proto.DashboardPointActiv>}
 */
proto.proto.GetDashboardPointMinMaxDateRequest.prototype.getActivIdsList = function() {
  return /** @type{!Array<!proto.proto.DashboardPointActiv>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardPointActiv, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardPointActiv>} value
 * @return {!proto.proto.GetDashboardPointMinMaxDateRequest} returns this
*/
proto.proto.GetDashboardPointMinMaxDateRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardPointActiv=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardPointActiv}
 */
proto.proto.GetDashboardPointMinMaxDateRequest.prototype.addActivIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardPointActiv, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardPointMinMaxDateRequest} returns this
 */
proto.proto.GetDashboardPointMinMaxDateRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardPointMinMaxDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardPointMinMaxDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardPointMinMaxDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardPointMinMaxDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    minDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maxDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardPointMinMaxDateResponse}
 */
proto.proto.GetDashboardPointMinMaxDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardPointMinMaxDateResponse;
  return proto.proto.GetDashboardPointMinMaxDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardPointMinMaxDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardPointMinMaxDateResponse}
 */
proto.proto.GetDashboardPointMinMaxDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardPointMinMaxDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardPointMinMaxDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardPointMinMaxDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardPointMinMaxDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaxDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string min_date = 1;
 * @return {string}
 */
proto.proto.GetDashboardPointMinMaxDateResponse.prototype.getMinDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardPointMinMaxDateResponse} returns this
 */
proto.proto.GetDashboardPointMinMaxDateResponse.prototype.setMinDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string max_date = 2;
 * @return {string}
 */
proto.proto.GetDashboardPointMinMaxDateResponse.prototype.getMaxDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardPointMinMaxDateResponse} returns this
 */
proto.proto.GetDashboardPointMinMaxDateResponse.prototype.setMaxDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardEarnedPointGroupBy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardEarnedPointGroupBy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedPointGroupBy.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoIdCount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectCount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activCount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descriptionCount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transactionDtCount: jspb.Message.getFieldWithDefault(msg, 5, ""),
    value: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardEarnedPointGroupBy}
 */
proto.proto.DashboardEarnedPointGroupBy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardEarnedPointGroupBy;
  return proto.proto.DashboardEarnedPointGroupBy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardEarnedPointGroupBy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardEarnedPointGroupBy}
 */
proto.proto.DashboardEarnedPointGroupBy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoIdCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivCount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionDtCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardEarnedPointGroupBy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardEarnedPointGroupBy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardEarnedPointGroupBy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoIdCount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectCount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivCount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescriptionCount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransactionDtCount();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string sso_id_count = 1;
 * @return {string}
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.getSsoIdCount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPointGroupBy} returns this
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.setSsoIdCount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_count = 2;
 * @return {string}
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.getProjectCount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPointGroupBy} returns this
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.setProjectCount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activ_count = 3;
 * @return {string}
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.getActivCount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPointGroupBy} returns this
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.setActivCount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description_count = 4;
 * @return {string}
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.getDescriptionCount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPointGroupBy} returns this
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.setDescriptionCount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string transaction_dt_count = 5;
 * @return {string}
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.getTransactionDtCount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardEarnedPointGroupBy} returns this
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.setTransactionDtCount = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 value = 6;
 * @return {number}
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardEarnedPointGroupBy} returns this
 */
proto.proto.DashboardEarnedPointGroupBy.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.repeatedFields_ = [4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardPointEarnedGroupByRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardPointEarnedGroupByRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardPointFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardPointSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.DashboardPointPagination.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    activIdsList: jspb.Message.toObjectList(msg.getActivIdsList(),
    proto.proto.DashboardPointActiv.toObject, includeInstance),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 10, ""),
    groupBy: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardPointEarnedGroupByRequest;
  return proto.proto.ListDashboardPointEarnedGroupByRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardPointEarnedGroupByRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardPointFilter;
      reader.readMessage(value,proto.proto.DashboardPointFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardPointSort;
      reader.readMessage(value,proto.proto.DashboardPointSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.DashboardPointPagination;
      reader.readMessage(value,proto.proto.DashboardPointPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 8:
      var value = new proto.proto.DashboardPointActiv;
      reader.readMessage(value,proto.proto.DashboardPointActiv.deserializeBinaryFromReader);
      msg.addActivIds(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardPointEarnedGroupByRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardPointEarnedGroupByRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardPointFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardPointSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.DashboardPointPagination.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.proto.DashboardPointActiv.serializeBinaryToWriter
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getGroupBy();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional DashboardPointFilter filter = 1;
 * @return {?proto.proto.DashboardPointFilter}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardPointFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardPointFilter, 1));
};


/**
 * @param {?proto.proto.DashboardPointFilter|undefined} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
*/
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardPointSort sort = 2;
 * @return {?proto.proto.DashboardPointSort}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardPointSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardPointSort, 2));
};


/**
 * @param {?proto.proto.DashboardPointSort|undefined} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
*/
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DashboardPointPagination pagination = 3;
 * @return {?proto.proto.DashboardPointPagination}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardPointPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardPointPagination, 3));
};


/**
 * @param {?proto.proto.DashboardPointPagination|undefined} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
*/
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated uint64 activity_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated DashboardPointActiv activ_ids = 8;
 * @return {!Array<!proto.proto.DashboardPointActiv>}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getActivIdsList = function() {
  return /** @type{!Array<!proto.proto.DashboardPointActiv>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardPointActiv, 8));
};


/**
 * @param {!Array<!proto.proto.DashboardPointActiv>} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
*/
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.proto.DashboardPointActiv=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardPointActiv}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.addActivIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.proto.DashboardPointActiv, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional string date_begin = 9;
 * @return {string}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string date_end = 10;
 * @return {string}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string group_by = 11;
 * @return {string}
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.getGroupBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByRequest} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByRequest.prototype.setGroupBy = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardPointEarnedGroupByResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardPointEarnedGroupByResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardEarnedPointGroupBy.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardPointEarnedGroupByRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByResponse}
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardPointEarnedGroupByResponse;
  return proto.proto.ListDashboardPointEarnedGroupByResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardPointEarnedGroupByResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByResponse}
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardEarnedPointGroupBy;
      reader.readMessage(value,proto.proto.DashboardEarnedPointGroupBy.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardPointEarnedGroupByRequest;
      reader.readMessage(value,proto.proto.ListDashboardPointEarnedGroupByRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardPointEarnedGroupByResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardPointEarnedGroupByResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardEarnedPointGroupBy.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardPointEarnedGroupByRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardEarnedPointGroupBy items = 1;
 * @return {!Array<!proto.proto.DashboardEarnedPointGroupBy>}
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardEarnedPointGroupBy>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardEarnedPointGroupBy, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardEarnedPointGroupBy>} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByResponse} returns this
*/
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardEarnedPointGroupBy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardEarnedPointGroupBy}
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardEarnedPointGroupBy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByResponse} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardPointEarnedGroupByRequest params = 2;
 * @return {?proto.proto.ListDashboardPointEarnedGroupByRequest}
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardPointEarnedGroupByRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardPointEarnedGroupByRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardPointEarnedGroupByRequest|undefined} value
 * @return {!proto.proto.ListDashboardPointEarnedGroupByResponse} returns this
*/
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardPointEarnedGroupByResponse} returns this
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardPointEarnedGroupByResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ExportListDashboardPointEarnedPointsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ExportListDashboardPointEarnedPointsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ExportListDashboardPointEarnedPointsResponse}
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ExportListDashboardPointEarnedPointsResponse;
  return proto.proto.ExportListDashboardPointEarnedPointsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ExportListDashboardPointEarnedPointsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ExportListDashboardPointEarnedPointsResponse}
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ExportListDashboardPointEarnedPointsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ExportListDashboardPointEarnedPointsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.proto.ExportListDashboardPointEarnedPointsResponse} returns this
 */
proto.proto.ExportListDashboardPointEarnedPointsResponse.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
