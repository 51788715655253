import { onActivated, onDeactivated, onMounted, onUnmounted, ref } from "vue";

export const useDeactivateTooltip = () => {
  const navigated = ref(false);

  onActivated(() => {
    navigated.value = false;
  })

  onDeactivated(() => {
    navigated.value = false
  })

  onUnmounted(() => {
    navigated.value = false
  })

  onMounted(() => {
    navigated.value = false
  })

  return {
    navigated
  };
};
