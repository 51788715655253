/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: project_info.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as project_info_pb from './project_info_pb'; // proto import: "project_info.proto"


export class DashboardProjectInfoServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetDashboardAllCount = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectInfoService/GetDashboardAllCount',
    grpcWeb.MethodType.UNARY,
    project_info_pb.GetDashboardAllCountRequest,
    project_info_pb.GetDashboardAllCountResponse,
    (request: project_info_pb.GetDashboardAllCountRequest) => {
      return request.serializeBinary();
    },
    project_info_pb.GetDashboardAllCountResponse.deserializeBinary
  );

  getDashboardAllCount(
    request: project_info_pb.GetDashboardAllCountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_info_pb.GetDashboardAllCountResponse>;

  getDashboardAllCount(
    request: project_info_pb.GetDashboardAllCountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_info_pb.GetDashboardAllCountResponse) => void): grpcWeb.ClientReadableStream<project_info_pb.GetDashboardAllCountResponse>;

  getDashboardAllCount(
    request: project_info_pb.GetDashboardAllCountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_info_pb.GetDashboardAllCountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectInfoService/GetDashboardAllCount',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardAllCount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectInfoService/GetDashboardAllCount',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardAllCount);
  }

  methodDescriptorListDashboardProjectInfo = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectInfoService/ListDashboardProjectInfo',
    grpcWeb.MethodType.UNARY,
    project_info_pb.ListDashboardProjectInfoRequest,
    project_info_pb.ListDashboardProjectInfoResponse,
    (request: project_info_pb.ListDashboardProjectInfoRequest) => {
      return request.serializeBinary();
    },
    project_info_pb.ListDashboardProjectInfoResponse.deserializeBinary
  );

  listDashboardProjectInfo(
    request: project_info_pb.ListDashboardProjectInfoRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_info_pb.ListDashboardProjectInfoResponse>;

  listDashboardProjectInfo(
    request: project_info_pb.ListDashboardProjectInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_info_pb.ListDashboardProjectInfoResponse) => void): grpcWeb.ClientReadableStream<project_info_pb.ListDashboardProjectInfoResponse>;

  listDashboardProjectInfo(
    request: project_info_pb.ListDashboardProjectInfoRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_info_pb.ListDashboardProjectInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectInfoService/ListDashboardProjectInfo',
        request,
        metadata || {},
        this.methodDescriptorListDashboardProjectInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectInfoService/ListDashboardProjectInfo',
    request,
    metadata || {},
    this.methodDescriptorListDashboardProjectInfo);
  }

  methodDescriptorListDashboardProjectInfoAccount = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectInfoService/ListDashboardProjectInfoAccount',
    grpcWeb.MethodType.UNARY,
    project_info_pb.ListDashboardProjectInfoAccountRequest,
    project_info_pb.ListDashboardProjectInfoAccountResponse,
    (request: project_info_pb.ListDashboardProjectInfoAccountRequest) => {
      return request.serializeBinary();
    },
    project_info_pb.ListDashboardProjectInfoAccountResponse.deserializeBinary
  );

  listDashboardProjectInfoAccount(
    request: project_info_pb.ListDashboardProjectInfoAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_info_pb.ListDashboardProjectInfoAccountResponse>;

  listDashboardProjectInfoAccount(
    request: project_info_pb.ListDashboardProjectInfoAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_info_pb.ListDashboardProjectInfoAccountResponse) => void): grpcWeb.ClientReadableStream<project_info_pb.ListDashboardProjectInfoAccountResponse>;

  listDashboardProjectInfoAccount(
    request: project_info_pb.ListDashboardProjectInfoAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_info_pb.ListDashboardProjectInfoAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectInfoService/ListDashboardProjectInfoAccount',
        request,
        metadata || {},
        this.methodDescriptorListDashboardProjectInfoAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectInfoService/ListDashboardProjectInfoAccount',
    request,
    metadata || {},
    this.methodDescriptorListDashboardProjectInfoAccount);
  }

  methodDescriptorPersonalListDashboardProjectInfoAccount = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectInfoService/PersonalListDashboardProjectInfoAccount',
    grpcWeb.MethodType.UNARY,
    project_info_pb.PersonalListDashboardProjectInfoAccountRequest,
    project_info_pb.PersonalListDashboardProjectInfoAccountResponse,
    (request: project_info_pb.PersonalListDashboardProjectInfoAccountRequest) => {
      return request.serializeBinary();
    },
    project_info_pb.PersonalListDashboardProjectInfoAccountResponse.deserializeBinary
  );

  personalListDashboardProjectInfoAccount(
    request: project_info_pb.PersonalListDashboardProjectInfoAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_info_pb.PersonalListDashboardProjectInfoAccountResponse>;

  personalListDashboardProjectInfoAccount(
    request: project_info_pb.PersonalListDashboardProjectInfoAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_info_pb.PersonalListDashboardProjectInfoAccountResponse) => void): grpcWeb.ClientReadableStream<project_info_pb.PersonalListDashboardProjectInfoAccountResponse>;

  personalListDashboardProjectInfoAccount(
    request: project_info_pb.PersonalListDashboardProjectInfoAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_info_pb.PersonalListDashboardProjectInfoAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectInfoService/PersonalListDashboardProjectInfoAccount',
        request,
        metadata || {},
        this.methodDescriptorPersonalListDashboardProjectInfoAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectInfoService/PersonalListDashboardProjectInfoAccount',
    request,
    metadata || {},
    this.methodDescriptorPersonalListDashboardProjectInfoAccount);
  }

  methodDescriptorExportListDashboardProjectInfoAccount = new grpcWeb.MethodDescriptor(
    '/proto.DashboardProjectInfoService/ExportListDashboardProjectInfoAccount',
    grpcWeb.MethodType.UNARY,
    project_info_pb.ListDashboardProjectInfoAccountRequest,
    project_info_pb.ExportListDashboardProjectInfoAccountResponse,
    (request: project_info_pb.ListDashboardProjectInfoAccountRequest) => {
      return request.serializeBinary();
    },
    project_info_pb.ExportListDashboardProjectInfoAccountResponse.deserializeBinary
  );

  exportListDashboardProjectInfoAccount(
    request: project_info_pb.ListDashboardProjectInfoAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_info_pb.ExportListDashboardProjectInfoAccountResponse>;

  exportListDashboardProjectInfoAccount(
    request: project_info_pb.ListDashboardProjectInfoAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_info_pb.ExportListDashboardProjectInfoAccountResponse) => void): grpcWeb.ClientReadableStream<project_info_pb.ExportListDashboardProjectInfoAccountResponse>;

  exportListDashboardProjectInfoAccount(
    request: project_info_pb.ListDashboardProjectInfoAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_info_pb.ExportListDashboardProjectInfoAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardProjectInfoService/ExportListDashboardProjectInfoAccount',
        request,
        metadata || {},
        this.methodDescriptorExportListDashboardProjectInfoAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardProjectInfoService/ExportListDashboardProjectInfoAccount',
    request,
    metadata || {},
    this.methodDescriptorExportListDashboardProjectInfoAccount);
  }

}

