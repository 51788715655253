import { RouteRecordRaw } from 'vue-router'

import segmentationRoutes from './segmentationSettings'
import mainPageScenariosRoutes from './mainPageScenarios'

const routes: RouteRecordRaw[] = [
  {
    path: '/recommend-system/preparing-difficult-tasks',
    name: 'PrepDifficultTasks',
    component: () => import('../views/PrepDifficultTasks.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Настройка блоков', to: { name: 'RecSystemCMS' } },
        { text: 'Блок «Список дел»', to: { name: 'TodoList' } },
        { text: 'Сервис подготовки сложных заданий' }
      ],
    },
  },
  {
    path: '/recommend-system/user-groups-scenarios',
    name: 'UserGroupScenarios',
    component: () => import('../views/UserGroupScenarios.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Сценарии для групп пользователей' }
      ],
    },
  },
  {
    path: '/recommend-system/nsi',
    name: 'RecSystemNsi',
    component: () => import('../views/RecSystemNsi.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'НСИ рекомендательной системы' }
      ],
    },
  },
  {
    path: '/recommend-system/nsi/parameters',
    name: 'RecSystemNsiParameters',
    component: () => import('../views/AdminUserGroup.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'НСИ рекомендательной системы', to: { name: 'RecSystemNsi' } },
        { text: 'Параметры рекомендательной системы' }
      ],
    },
  },
  {
    path: '/recommend-system/cms',
    name: 'RecSystemCMS',
    component: () => import('../views/CMSPage.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Настройка блоков' },
      ],
    },
  },
  // {
  //   path: '/recommend-system/cms/segmentation',
  //   name: 'RecSystemCMSSegmentation',
  //   component: () => import('../components/CMS/Segmentation/SegmentationPage.vue'),
  //   meta: {
  //     breadCrumb: [
  //       { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
  //       { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
  //       { text: 'CMS', to: { name: 'RecSystemCMS' } },
  //       { text: 'Сегментация аудитории' }
  //     ],
  //   },
  // },
  {
    path: '/recommend-system/cms/available-activities',
    name: 'RecSystemCMSAvailableActivities',
    component: () => import('../components/CMS/AvailableActivities/AvailableActivitiesList.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Настройка блоков', to: { name: 'RecSystemCMS' } },
        { text: 'Блок «Доступные активности»' }
      ],
    },
  },
  {
    path: '/recommend-system/cms/available-activities/setting-automatic-activities',
    name: 'RecSystemCMSSettingAutomaticActivities',
    component: () => import('../components/CMS/AvailableActivities/AvailableActivityBlocks/SettingAutomaticActivities.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Настройка блоков', to: { name: 'RecSystemCMS' } },
        { text: 'Блок «Доступные активности»', to: { name: 'RecSystemCMSAvailableActivities' } },
        { text: 'Настройка активностей' }
      ],
    },
  },
  {
    path: '/recommend-system/cms/special',
    name: 'RecSystemCMSSpecial',
    component: () => import('../components/CMS/SpecialForYou/SpecialPage.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Настройка блоков', to: { name: 'RecSystemCMS' } },
        { text: 'Блок "Специально для вас"' }
      ],
    }
  },
  {
    path: '/recommend-system/cms/todo-list',
    name: 'TodoList',
    component: () => import('../components/CMS/AvailableActivities/TodoList.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Настройка блоков', to: { name: 'RecSystemCMS' } },
        { text: 'Блок "Список дел"' }
      ],
    }
  },
  {
    path: '/recommend-system/cms/available-activities/projects-info',
    name: 'RecSystemProjects',
    component: () => import('../components/CMS/AvailableActivities/Projects/ProjectsPageCms.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Настройка блоков', to: { name: 'RecSystemCMS' } },
        { text: 'Блок «Доступные активности»', to: { name: 'RecSystemCMSAvailableActivities' } },
        { text: 'Справочник проектов' }
      ],
    }
  },
]

routes.push(...segmentationRoutes, ...mainPageScenariosRoutes)

export default {
  routes
}
