export function saveFile(reportName: string, bytes: any) {
  // const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${b64}`
  // const downloadLink = document.createElement('a');
  // document.body.appendChild(downloadLink);
  //
  // downloadLink.href = linkSource;
  // downloadLink.target = '_self';
  // downloadLink.download = 'output.xlsx';
  // downloadLink.click();
  const blob = new Blob([bytes], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
  const link = document.createElement('a')

  link.href = window.URL.createObjectURL(blob)
  link.download = reportName
  link.click()
}