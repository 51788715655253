

import store from '@/store'
import MainMixin from '@/mixins/main'
import { defineComponent } from 'vue'
import ExportMessages from "@/components/modules/polls/views/answers/ExportMessages.vue";

export default defineComponent({
  name: 'TableMessageSource',

  components: {
    ExportMessages,
  },

  mixins: [MainMixin],

  props: {
    message: Object as any,
    isFull: Boolean,
  },

  data() {
    return {
      columnsSource: [
        {
          name: 'id',
          field: 'id',
          label: 'Код',
          align: 'center',
        },
        {
          name: 'sso_id',
          field: 'sso_id',
          label: 'Пользователь',
          align: 'left',
        },
        {
          name: 'text',
          field: 'text',
          label: 'Ответ',
          align: 'left',
        },
        {
          name: 'project',
          field: 'project',
          label: 'Проект',
          align: 'left'
        },
        {
          name: 'type',
          field: 'type',
          label: 'Действие',
          align: 'left'
        },
        {
          name: 'time',
          field: 'time',
          label: 'Время',
          align: 'center',
        },
      ],
      sourceFilter: [
        {
          field_name: 'al.source_id',
          operand: '>',
          value: 0 as any,
          or: [] as any,
        },
      ],
      sourcePagination: {
        sortBy: 'asrc.message_text',
        descending: false,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 0,
      },
      rowsPerPageSourceOptions: [10, 25, 50, 100],
      markedFind: [] as string[],
      search: '',
      navigated:false
    }
  },

  computed: {

    isMessageSrcSourceBusy() {
      return store.getters.isMessageSrcSourceBusy
    },

    getMessageSrcSourceItems() {
      const messageItems = store.getters.getMessageSrcSourceItems
      console.log('MSG ITEMS', messageItems)
      let rows: any = []
      if (messageItems !== undefined) {
        for (let messageItem of messageItems) {
          rows.push({
            id: messageItem.getSourceId(),
            guid: messageItem.getAccountGuid(),
            sso_id: messageItem.getSsoId(),
            text: messageItem.getMessageSrcText(),
            project: messageItem.getProject(),
            type: messageItem.getMessageType(),
            time: messageItem.getMessageSrcTime(),
          })
        }
      }
      return rows
    },

  },

  created() {
    this.loadData()
    this.navigated = false
  },
  activated() {
      this.navigated = false
  },
  deactivated() {
    this.navigated = false
  },

  methods: {

    // Переход на профиль пользователя
    openUserProfile(profile: string) {
      const routeData = this.$router.resolve({
        path: '/user-profile/' + profile,// + '?group=' + this.groupId + '&poll=' + this.pollId + '&question=' + this.questionId,
      })
      window.open(routeData.href, '_blank')
    },

    closeTableMessageSource() {
      this.$emit('hideModal')
    },

    onRequestSource(props: any) {
      console.log('onRequestSource props', props)
      this.sourcePagination = props.pagination
      this.loadSourceMessages(false)
    },

    findMessages() {
      let extFilter: any[] = []
      this.markedFind = []

      if (this.search.length) {
        extFilter.push({
          field_name: 'asrc.message_text',
          operand: 'ilike',
          value: this.search,
        }, {
          field_name: 'ac.sso_id::d_char64',
          operand: 'ilike',
          value: this.search,
        })
        this.markedFind.push(this.search)
      }

      this.sourceFilter[0].or = extFilter
      this.loadSourceMessages(true) // true - переключаем на первую страницу
    },

    strMarkRedPlus(search: string, subject: string) {
      let escapeRegExp = function (str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
      }

      return subject.replace(
          new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
          "<span style='color: red;'><b>$1</b></span>"
      )
    },

    markedAll(text: string) {
      const marked = this.markedFind

      if (marked && marked.length) {
        marked.sort((a: string, b: string) => b.length - a.length)
        marked.forEach(
            (item: string) => (text = this.strMarkRedPlus(item, text))
        )
      }

      return text
    },

    // Подгрузка непривязанных ответов
    loadSourceMessages(isResetPagination: boolean) {
      if (isResetPagination) {
        this.sourcePagination.page = 1
        this.sourcePagination.rowsNumber = 0
      }
      this.sourcePagination.sortBy = 'asrc.message_text'
      this.sourcePagination.descending = false

      console.log('loadSourceMessages this.sourceFilter', this.sourceFilter)
      store
          .dispatch('getMessageSrcSourceItems', {
            filter: this.sourceFilter,
            sort: {
              name: this.sourcePagination.sortBy,
              exp: this.sourcePagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.sourcePagination.page,
              limit: this.sourcePagination.rowsPerPage,
              pages: 0,
              cnt: this.sourcePagination.rowsNumber,
            },
            message_grp_id: this.message.id,
            is_full: this.isFull,
          })
          .then(
              (resolve) => {
                console.log('getMessageSrcSourceItems resolve', resolve)
                this.sourcePagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getMessageSrcSourceItems reject', reject)
              }
          )
    },

    loadPersonalSourceMessages() {
      store
          .dispatch('getPersonalMessageSrcSourceItems', {
            filter: this.sourceFilter,
            message_grp_id: this.message.id,
            is_full: this.isFull,
          })
          .then(() => {
            this.closeTableMessageSource()

            this.$router.push({
              name: 'UserProjects',
              query: {
                data: 'personal'
              }
            })
            .finally(() => {
              this.navigated = true
            })
          })
    },

    loadData() {
      this.markedFind = []
      this.search = ''

      this.loadSourceMessages(true)
    },
  },
})
